import LoginPage from "../screens/auth/LoginPage";
import HomePage from "../screens/home/HomePage";
import ProductDetail from "../screens/product/ProductDetail";
import SearchResult from "../screens/product/SearchResult";
import Category from "../screens/product/Category";
import CategoryDetail from "../screens/product/CategoryDetail";
import SignIn from "../screens/auth/SignIn";
import SignUp from "../screens/auth/SignUp";
import CreateProfile from "../screens/user/CreateProfile";
import CartList from "../screens/cart/CartList";
import ConfirmOrder from "../screens/order/ConfirmOrder";
import OrderSuccess from "../screens/order/OrderSuccess";
import MyOrdersToShip from "../screens/user/MyOrdersToShip";
import MyOrdersReceived from "../screens/user/MyOrdersReceived";
import MyOrdersShipping from "../screens/user/MyOrdersShipping";
import ProfilePage from "../screens/user/ProfilePage";
import ResetPassword from "../screens/user/ResetPassword";
import EditShippingInfo from "../screens/user/EditShippingInfo";
import OnBoarding from "../screens/guide/OnBoarding";
import AccountDetail from "../screens/user/AccountDetail";
import ConfirmShipping from "../screens/user/Confirm-Shipping";
import EditAccountDetail from "../screens/user/EditAccountDetail";
import LuckyDraw from "../screens/LuckyDraw/LuckyDraw";
import TicketBuyingSuccess from "../screens/LuckyDraw/TicketBuyingSuccess";
import LuckyDrawLive from "../screens/LuckyDraw/LuckyDrawLive";
import PrizeWinners from "../screens/LuckyDraw/PrizeWinners";
import Token from "../screens/Token/Token";
import LuckyDrawDetail from "../screens/LuckyDraw/LuckyDrawDetail";
import ShippingOrderSuccess from "../screens/order/ShippingOrderSuccess";
import PrizeWinnerDetail from "../screens/LuckyDraw/PrizeWinnerDetail";
import Promotion from "../screens/guide/Promotion";
import MyOrdersShippingDetail from "../screens/user/MyOrdersShippingDetail";
import PublicReview from "../screens/review/PublicReview";
import MyReview from "../screens/review/MyReview";
import LuckyDrawAllPrize from "../screens/LuckyDraw/LuckyDrawAllPrize";
import ReferralGift from "../screens/referral/ReferralGift";

export const RouteConfig = [
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/product-detail",
    element: <ProductDetail />,
    special_access: "productdetail",
  },
  {
    path: "/search-result",
    element: <SearchResult />,
  },
  {
    path: "/category",
    element: <Category />,
    special_access: "category",
  },
  {
    path: "/category-detail",
    element: <CategoryDetail />,
  },
  {
    path: "/create-profile",
    element: <CreateProfile />,
    special_access: "user",
  },
  {
    path: "/profile",
    element: <ProfilePage />,
    special_access: "user",
  },
  {
    path: "/cart-list",
    element: <CartList />,
    special_access: "user",
  },
  {
    path: "/confirm-order",
    element: <ConfirmOrder />,
    special_access: "user",
  },
  {
    path: "/order-success",
    element: <OrderSuccess />,
    special_access: "user",
  },
  {
    path: "/shipping-order-success",
    element: <ShippingOrderSuccess />,
    special_access: "user",
  },
  {
    path: "/my-orders-to-ship",
    element: <MyOrdersToShip />,
    special_access: "user",
  },
  {
    path: "/my-orders-shipping",
    element: <MyOrdersShipping />,
    special_access: "user",
  },
  {
    path: "/my-orders-shipping-detail",
    element: <MyOrdersShippingDetail />,
    special_access: "user",
  },
  {
    path: "/my-orders-received",
    element: <MyOrdersReceived />,
    special_access: "user",
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/edit-shipping-info",
    element: <EditShippingInfo />,
    special_access: "user",
  },
  {
    path: "/onboarding",
    element: <OnBoarding />,
  },
  {
    path: "/account-detail",
    element: <AccountDetail />,
    special_access: "user",
  },
  {
    path: "/confirm-shipping",
    element: <ConfirmShipping />,
    special_access: "user",
  },
  {
    path: "/edit-account-detail",
    element: <EditAccountDetail />,
    special_access: "user",
  },
  {
    path: "/lucky-draw",
    element: <LuckyDraw />,
  },
  {
    path: "/lucky-draw-all-prize",
    element: <LuckyDrawAllPrize />,
  },
  {
    path: "/ticket-buy-success",
    element: <TicketBuyingSuccess />,
  },
  {
    path: "/lucky-draw-live",
    element: <LuckyDrawLive />,
  },
  {
    path: "/lucky-draw-detail",
    element: <LuckyDrawDetail />,
  },
  {
    path: "/prize-winners",
    element: <PrizeWinners />,
  },
  {
    path: "/prize-winner-detail",
    element: <PrizeWinnerDetail />,
  },
  {
    path: "/token",
    element: <Token />,
  },
  {
    path: "/referral-gift",
    element: <ReferralGift />,
  },
  {
    path: "/promotion",
    element: <Promotion />,
  },
  {
    path: "/reviews",
    element: <PublicReview />,
  },
  {
    path: "/my-reviews",
    element: <MyReview />,
  },
];
