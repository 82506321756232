import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import BottomNav from "../../layout/BottomNav";
import { ArrowLeftIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ClipLoader from "react-spinners/ClipLoader";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";

export default function CartList() {
  const navigate = useNavigate();
  // Change language
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const cartList = useSelector((state) => state?.cart?.list?.data?.data);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [alertmessage, setAlertmessage] = useState("");

  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  const areAllSelected = selectedItems.size === cartList?.length;

  const handleSelectionChange = (productid) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (newSelectedItems.has(productid)) {
        newSelectedItems.delete(productid);
      } else {
        newSelectedItems.add(productid);
      }
      return newSelectedItems;
    });
  };

  const handleSelectAll = () => {
    if (selectedItems.size === cartList.length) {
      setSelectedItems(new Set());
    } else {
      setSelectedItems(new Set(cartList.map((item) => item?.product?.id)));
    }
  };

  const handleRemoveCart = () => {
    callApi("cart/removeAll")
      .withBody({ product_id: [...selectedItems] })
      .loadingGroup("removeCart")
      .executeDispatch()
      .then(() => {
        callApi("cart/list").executeDispatch();
      });
  };

  const handleUpdateCart = (type, item) => {
    if (type == "plus") {
      callApi("cart/updateCart")
        .withBody({ product_id: item?.product?.id, qty: item?.qty + 1 })
        .loadingGroup("cartlist" + item?.id)
        .executeDispatch()
        .then((res) => {
          if (res?.status_code == 200) {
            setAlertmessage(
              "1 " + res?.data?.product?.name + " has been added to Cart"
            );
          }
          callApi("cart/list")
            .loadingGroup("cartlist" + item?.id)
            .executeDispatch();
        });
    } else if (type == "minus") {
      callApi("cart/updateCart")
        .withBody({ product_id: item?.product?.id, qty: item?.qty - 1 })
        .loadingGroup("cartlist" + item?.id)
        .executeDispatch()
        .then((res) => {
          if (res?.status_code == 200) {
            setAlertmessage(
              "1 " + res?.data?.product?.name + " has been removed from Cart"
            );
          }
          callApi("cart/list")
            .loadingGroup("cartlist" + item?.id)
            .executeDispatch();
        });
    }
  };

  return (
    <>
      {alertmessage && (
        <AlertPopup
          type={"success"}
          btnLabel={"Ok"}
          text={alertmessage}
          onClick={() => setAlertmessage(null)}
        />
      )}

      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div id="body" className="bottombar" style={{ overflow: "hidden auto" }}>
        <section className="container p-lr-16 m-b-16">
          <div className="flex items-center justify-between">
            <span className="flex items-center fs-16 font-bold text-500">
              <ArrowLeftIcon
                onClick={() => navigate(-1)}
                className="flex w-[15px] h-[15px] m-all-5 justify-center items-center shrink-0 font-bold text-500 cursor-pointer"
              />
              {getLanguageFile.shopping_cart} ({cartList?.length} items)
            </span>
          </div>
        </section>

        <section className="container p-lr-16 m-b-24">
          <div className="flex items-center">
            <input
              id="select-all-checkbox"
              type="checkbox"
              value=""
              className={`w-4 h-4 m-r-10 appearance-none
                border-[3px] border-white shadow-radio-button
                ${!areAllSelected && "hover:shadow-hover-radio-button"}
                checked:shadow-active-radio-button rounded-full checked:bg-blue-600 focus:outline-none`}
              onChange={handleSelectAll}
              checked={areAllSelected}
            />
            <label
              htmlFor="select-all-checkbox"
              className="fs-12 text-gray-900"
            >
              {getLanguageFile.allitem}
            </label>

            <div className="w-[1px] h-[13px] bg-Light/active m-lr-8"></div>

            <button
              onClick={handleRemoveCart}
              className="flex justify-center fs-12 text-blue-500 text-center"
            >
              <LoadingComponent
                loadingGroup={"removeCart"}
                loadingDesign={<ClipLoader color="#3b82f6" size={10} />}
              >
                {areAllSelected
                  ? getLanguageFile.removeall
                  : getLanguageFile.removeselected}
              </LoadingComponent>
            </button>
          </div>
        </section>

        <section className="container p-lr-16 m-b-16">
          <div className="flex flex-col">
            {cartList?.map((item) => (
              <div key={item?.id}>
                <div className="flex relative">
                  <input
                    type="checkbox"
                    value=""
                    name={`default-checkbox-${item?.id}`}
                    className={`flex-none w-4 h-4 m-r-10 my-auto appearance-none
                        border-[3px] border-white shadow-radio-button
                        ${
                          !selectedItems.has(item?.product?.id) &&
                          "hover:shadow-hover-radio-button"
                        }
                        checked:shadow-active-radio-button rounded-full checked:bg-blue-600 focus:outline-none
                      `}
                    onChange={() => handleSelectionChange(item?.product?.id)}
                    checked={selectedItems.has(item?.product?.id)}
                  />

                  <div href="product-detail" className="w-1/2 relative">
                    <img
                      src={
                        item?.product?.feature_image
                          ? item?.product?.feature_image
                          : LuckydrawItem1
                      }
                      alt="Slide 1"
                      className=""
                    />
                  </div>

                  <div className="flex-grow w-1/2 p-lr-8 p-tb-16">
                    <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-2">
                      {item?.product?.name}
                    </div>
                    <span className="flex items-center leading-normal mb-4">
                      <span className="fs-11 font-bold me-1">MMK</span>
                      <span className="fs-16 font-extrabold leading-[16px]">
                        {item?.product?.actual_price}
                      </span>
                    </span>

                    {/* {item?.product?.off_price > 0 && (
                      <span className="flex items-center text-300 leading-normal">
                        <span className="fs-11 me-1">was</span>
                        <span className="fs-11">MMK</span>
                        <span className="fs-11 line-through leading-[16px] me-1">
                          {item?.product?.actual_price}
                        </span>
                      </span>
                    )} */}

                    <div className="flex items-center">
                      <LoadingComponent
                        loadingGroup={"cartlist" + item?.id}
                        loadingDesign={
                          <button
                            disabled
                            className="cursor-not-allowed flex items-center justify-center bg-white text-500 font-semibold rounded-full w-[32px] h-[32px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover"
                          >
                            <MinusIcon className="flex w-[15px] h-[15px] m-all-5 justify-center items-center shrink-0 font-bold text-500" />
                          </button>
                        }
                      >
                        <button
                          onClick={() => handleUpdateCart("minus", item)}
                          className="flex items-center justify-center bg-white text-500 font-semibold rounded-full w-[32px] h-[32px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover"
                        >
                          <MinusIcon className="flex w-[15px] h-[15px] m-all-5 justify-center items-center shrink-0 font-bold text-500" />
                        </button>
                      </LoadingComponent>

                      <span className="fs-12 m-lr-8">
                        <LoadingComponent
                          loadingGroup={"cartlist" + item?.id}
                          loadingDesign={<ClipLoader size={10} />}
                        >
                          {item?.qty}
                        </LoadingComponent>
                      </span>

                      <LoadingComponent
                        loadingGroup={"cartlist" + item?.id}
                        loadingDesign={
                          <button
                            disabled
                            className="cursor-not-allowed flex items-center justify-center bg-foundation-grey-light-hover text-500 font-semibold rounded-full w-[32px] h-[32px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover"
                          >
                            <PlusIcon className="flex w-[15px] h-[15px] m-all-5 justify-center items-center shrink-0 font-bold text-500" />
                          </button>
                        }
                      >
                        <button
                          onClick={() => handleUpdateCart("plus", item)}
                          className="flex items-center justify-center bg-foundation-grey-light-hover text-500 font-semibold rounded-full w-[32px] h-[32px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover"
                        >
                          <PlusIcon className="flex w-[15px] h-[15px] m-all-5 justify-center items-center shrink-0 font-bold text-500" />
                        </button>
                      </LoadingComponent>
                    </div>
                  </div>
                </div>

                <div className="border-t-2 border-Light/active mb-4"></div>
              </div>
            ))}
          </div>
        </section>

        <section className="container m-b-16">
          <div className="w-100 p-lr-16 bottom-0 bg-white">
            <button
              onClick={() => navigate("/confirm-order")}
              className="flex items-center justify-center m-tb-16 bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100"
            >
              {getLanguageFile.checkout}
            </button>
          </div>
        </section>
      </div>

      <BottomNav active={1} getLanguageFile={getLanguageFile} />
    </>
  );
}
