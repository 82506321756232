import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import {
  ArrowLeftIcon,
  StarIcon,
  PencilSquareIcon,
  PlusIcon,
  MinusIcon,
  HomeIcon,
  ShoppingCartIcon,
  GiftIcon,
} from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import AuthHeader from "../../layout/AuthHeader";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import { slice } from "../../redux/reducers/reducer";
import { dispatchStore } from "../../lib/dispatchStore";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { BeatLoader } from "react-spinners";

export default function ConfirmOrder() {
  const navigate = useNavigate();
  const location = useLocation();
  const { product, qty } = location.state || {};

  const fileInputRef = useRef(null);

  const [serverError, setServerError] = useState(null);

  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const [useCardNextTime, setUseCardNextTime] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [totalPrices, setTotalPrices] = useState(null);
  const [paymentImage, setPaymentImage] = useState(null);

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const AccessToken = useSelector((state) => state.AccessToken);
  const profile = useSelector((state) => state?.profile?.profile?.data?.data);
  const cartList = useSelector((state) => state?.cart?.list?.data?.data);
  const paymentlist = useSelector(
    (state) => state?.cart?.paymentlist?.data?.data
  );
  const paymentdetail = useSelector(
    (state) => state?.cart?.paymentdetail?.data?.data
  );

  async function handleCheckout() {
    if (selectedPaymentOption) {
      // fileInputRef.current.click();
      if (paymentImage) {
        const formData = new FormData();
        formData.append("payment_id", selectedPaymentOption);
        formData.append("payment_screenshot", paymentImage);

        if (!product && !qty) {
          callApi("cart/checkout")
            .withBody(formData)
            .loadingGroup("checkout")
            .executeDispatch()
            .then((res) => {
              if (res?.status_code == 200) {
                navigate("/order-success", { state: { data: res } });
              } else {
                setServerError(res?.message);
              }
            });
        } else if (product && qty) {
          formData.append("product_id", product?.id);
          formData.append("qty", qty);
          callApi("cart/buynow")
            .withBody(formData)
            .loadingGroup("yo")
            .executeDispatch()
            .then((res) => {
              if (res?.status_code == 200) {
                navigate("/order-success", { state: { data: res } });
              } else {
                setServerError(res?.message);
              }
            });
        }
      } else {
        setServerError("Please select Payment Screenshot");
      }
    } else {
      setServerError(getLanguageFile.please_select_payment);
    }
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      // Store the selected file in a ref
      setServerError("Please select Payment Screenshot");
      return;
    }
    setPaymentImage(file);
  };

  const toggleUseCardNextTime = () => {
    setUseCardNextTime(!useCardNextTime);
  };

  useEffect(() => {
    if (AccessToken) {
      callApi("profile/profile").executeDispatch();
      callApi("cart/list").executeDispatch();
    }
  }, [AccessToken]);

  useEffect(() => {
    callApi("cart/paymentlist").executeDispatch();
    dispatchStore(slice?.cart?.actions.clearSliceElement("paymentdetail"));
  }, []);

  useEffect(() => {
    if (!product && !qty) {
      if (cartList?.length == 0) {
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    if (selectedPaymentOption) {
      callApi("cart/paymentdetail")
        .withKeyParameter({ paymentID: selectedPaymentOption })
        .executeDispatch();
    }
  }, [selectedPaymentOption]);

  useEffect(() => {
    if (!product && !qty) {
      if (cartList) {
        const TempCalculation = cartList?.reduce(
          (acc, item) => acc + item?.product.actual_price * item?.qty,
          0
        );
        setTotalPrices(TempCalculation);
      }
    } else {
      setTotalPrices(product?.actual_price * qty);
    }
  }, [cartList]);

  return (
    <>
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={getLanguageFile.propbox_ok}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}
      <AuthHeader title={getLanguageFile.confirm_order} backkey={true} />

      <div
        id="body"
        className="auth"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <section className="container p-lr-16 m-b-16">
          <div className="flex items-center justify-between">
            <span className="flex items-center fs-16 font-bold text-500">
              {getLanguageFile.billing_info}
              <span className="text-red-500">*</span>
            </span>
          </div>
        </section>

        <section className="container p-lr-16 m-b-16">
          <div className="p-4 m-b-16 bg-foundation-grey-light-hover rounded-lg">
            <h2 className="fs-14 font-bold text-gray-800 m-b-4">
              {profile?.name}
            </h2>
            <div className="">
              <p className="flex justify-between">
                <span className="fs-12 text-gray-600 m-b-4">
                  {profile?.phone}
                </span>
              </p>
              <p className="flex justify-between">
                <span className="fs-12 text-gray-600 m-b-4">
                  {profile?.address +
                    ", " +
                    profile?.township?.name +
                    ", " +
                    profile?.region?.name}
                </span>
              </p>
            </div>
          </div>
        </section>

        <section className="container p-lr-16 m-b-24">
          <div className="flex items-center fs-16 font-bold text-500">
            {getLanguageFile.my_orders}
          </div>
        </section>

        <section className="container p-lr-16 m-b-16">
          <div className="flex flex-col">
            {!product && !qty ? (
              cartList?.map((item, key) => (
                <div key={key}>
                  <div className="flex relative">
                    <div href="product-detail" className="w-1/2 relative">
                      <img
                        src={
                          item?.product?.feature_image
                            ? item?.product?.feature_image
                            : LuckydrawItem1
                        }
                        alt="Slide 1"
                        className=""
                      />

                      {/* <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                      <span className="text-white fs-8 font-medium">
                        60% off
                      </span>
                    </div> */}
                    </div>

                    <div className="flex-grow w-1/2 p-lr-8 p-tb-16">
                      <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                        {item?.product?.name}
                      </div>
                      <span className="flex items-center leading-normal">
                        <span className="fs-11 font-bold me-1">MMK</span>
                        <span className="fs-16 font-extrabold leading-[16px]">
                          {item?.product?.actual_price}
                        </span>
                      </span>
                      {/* {item?.product?.off_price > 0 && (
                        <span className="flex items-center text-300 leading-normal">
                          <span className="fs-11 me-1">was</span>
                          <span className="fs-11">MMK</span>
                          <span className="fs-11 line-through leading-[16px] me-1">
                            {item?.product?.actual_price}
                          </span>
                          <span className="fs-11 font-medium text-500">
                            60% off
                          </span>
                        </span>
                      )} */}
                      <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                        {getLanguageFile.quantity}: {item?.qty}
                      </div>

                      {/* <div className="m-b-16">
                      <span className="fs-11 text-300 leading-normal me-1">
                        {getLanguageFile.shipping}
                      </span>

                      <span className="fs-11 text-red-500 leading-[16px]">
                        MMK 5,000
                      </span>
                    </div> */}

                      {/* <div className="flex m-b-8">
                      <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                        <span className="text-500 fs-12 font-medium">
                          White
                        </span>
                      </div>

                      <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                        <span className="text-500 fs-12 font-medium">
                          Small
                        </span>
                      </div>
                    </div> */}
                    </div>
                  </div>

                  <div className="border-t-2 border-Light/active mb-4"></div>
                </div>
              ))
            ) : (
              <div>
                <div className="flex relative">
                  <div href="product-detail" className="w-1/2 relative">
                    <img
                      src={
                        product?.feature_image
                          ? product?.feature_image
                          : LuckydrawItem1
                      }
                      alt="Slide 1"
                      className=""
                    />

                    {/* <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                      <span className="text-white fs-8 font-medium">
                        60% off
                      </span>
                    </div> */}
                  </div>

                  <div className="flex-grow w-1/2 p-lr-8 p-tb-16">
                    <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                      {product?.name}
                    </div>
                    <span className="flex items-center leading-normal">
                      <span className="fs-11 font-bold me-1">MMK</span>
                      <span className="fs-16 font-extrabold leading-[16px]">
                        {product?.actual_price}
                      </span>
                    </span>
                    {/* {product?.off_price > 0 && (
                      <span className="flex items-center text-300 leading-normal">
                        <span className="fs-11 me-1">was</span>
                        <span className="fs-11">MMK</span>
                        <span className="fs-11 line-through leading-[16px] me-1">
                          {product?.actual_price}
                        </span>
                        <span className="fs-11 font-medium text-500">
                          60% off
                        </span>
                      </span>
                    )} */}
                    <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                      {getLanguageFile.quantity}: {qty}
                    </div>

                    {/* <div className="m-b-16">
                      <span className="fs-11 text-300 leading-normal me-1">
                        {getLanguageFile.shipping}
                      </span>

                      <span className="fs-11 text-red-500 leading-[16px]">
                        MMK 5,000
                      </span>
                    </div> */}

                    {/* <div className="flex m-b-8">
                      <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                        <span className="text-500 fs-12 font-medium">
                          White
                        </span>
                      </div>

                      <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                        <span className="text-500 fs-12 font-medium">
                          Small
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="border-t-2 border-Light/active mb-4"></div>
              </div>
            )}

            <div className="flex justify-end border-t pt-2 mb-4">
              <p className="text-500 font-bold fs-14">
                {getLanguageFile.total_amount}: {totalPrices}MMK
              </p>
            </div>
          </div>
        </section>

        {/* Section 3: Payment Methods */}
        <section className="px-4 mb-4">
          <div className="mb-2">
            <h2 className="fs-14 font-bold">
              {getLanguageFile.payment_method}{" "}
              <span className="text-red-500">*</span>
            </h2>
          </div>
          <div className="border border-gray-300 rounded-lg p-4">
            <div className="mb-0">
              <div className="flex items-center space-x-4 mb-0">
                {paymentlist?.map((item, key) => (
                  <label key={key} className="inline-flex items-center">
                    <input
                      type="radio"
                      name="paymentMethod"
                      className="form-radio"
                      value={item?.id}
                      checked={selectedPaymentOption == item?.id}
                      onChange={(e) => setSelectedPaymentOption(e.target.value)}
                    />
                    {item?.logo ? (
                      <img
                        className="w-[auto] h-[20px] ms-1"
                        src={item?.logo}
                        alt={item?.name}
                      />
                    ) : (
                      <span className="ml-2 text-500 fs-12">{item?.name}</span>
                    )}
                  </label>
                ))}
              </div>
              <div className="grid grid-cols-1 gap-4">
                {paymentdetail?.payment_numbers?.[0]?.account_name && (
                  <div className="text-500 fs-12 mt-4">
                    {getLanguageFile.merchant_name}:{" "}
                    {paymentdetail?.payment_numbers?.[0]?.account_name}
                  </div>
                )}
                {paymentdetail?.payment_numbers?.[0]?.account_number && (
                  <div className="text-500 fs-12">
                    {getLanguageFile.merchant_account_number}:{" "}
                    {paymentdetail?.payment_numbers?.[0]?.account_number}
                  </div>
                )}
                {paymentdetail?.payment_numbers?.[0]?.account_number && (
                  <div className="flex">
                    <div className="text-500 fs-12">
                      {getLanguageFile.payment_screenshort}:{" "}
                    </div>
                    <label className="text-black fs-12 cursor-pointer bg-gold-500 py-3 px-4 rounded-lg ms-2">
                      {paymentImage
                        ? getLanguageFile.reselect
                        : getLanguageFile.browse}

                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className="hidden"
                        // style={{ display: "none" }}
                      />
                    </label>
                  </div>
                )}
                {paymentImage && (
                  <img
                    src={URL.createObjectURL(paymentImage)}
                    alt="Selected"
                    className="max-w-full max-h-80 rounded-lg border-2 border-gray-300 p-2"
                  />
                )}
                {/* <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.card_number}
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="number"
                  />
                </div>
                <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.name_on_card}
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="name"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-500 fs-12">
                      {getLanguageFile.expire_date}
                    </label>
                    <input
                      type="text"
                      className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                      placeholder="MM / YY"
                    />
                  </div>
                  <div>
                    <label className="text-500 fs-12">
                      {getLanguageFile.cvc}
                    </label>
                    <input
                      type="text"
                      className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                      placeholder="123"
                    />
                  </div>
                </div> */}
              </div>
              {/* <div className="mt-4 flex items-center">
                <button
                  type="button"
                  onClick={toggleUseCardNextTime}
                  className="flex items-center"
                >
                  <div
                    className={`relative inline-block w-12 h-6 align-middle select-none transition duration-200 ease-in-out ${
                      useCardNextTime ? "bg-green-500" : "bg-gray-300"
                    } border border-gray-300 rounded-full`}
                  >
                    <input
                      type="checkbox"
                      name="useCardNextTime"
                      id="useCardNextTime"
                      className="absolute opacity-0 w-0 h-0"
                      checked={useCardNextTime}
                      onChange={toggleUseCardNextTime}
                    />
                    <span
                      className={`absolute left-0 inline-block w-6 h-6 transform bg-white border rounded-full transition-transform duration-200 ease-in-out ${
                        useCardNextTime ? "translate-x-6" : "translate-x-0"
                      }`}
                    ></span>
                  </div>
                  <span className="ml-2 text-500 fs-12 font-medium">
                    {getLanguageFile.use_card_next_time}
                  </span>
                </button>
              </div> */}
            </div>
          </div>

          <div className="text-500 font-bold fs-12 my-2">
            *{getLanguageFile?.payment_method_description1}
            <span className="text-500 font-bold fs-12 my-2">
              {product && qty ? product?.actual_price * qty : totalPrices}
            </span>
            {getLanguageFile?.payment_method_description2}
          </div>
        </section>

        <section className="container m-b-16">
          <div className="w-100 p-lr-16 bottom-0 bg-white">
            <LoadingComponent
              loadingGroup={"checkout"}
              loadingDesign={
                <button
                  disabled={true}
                  className="flex items-center justify-center m-tb-16 bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 disabled:cursor-not-allowed"
                >
                  <BeatLoader color="#ffffff" size={13} />
                </button>
              }
            >
              <button
                onClick={() => handleCheckout()}
                className="flex items-center justify-center m-tb-16 bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100"
              >
                {getLanguageFile.checkout}
              </button>
            </LoadingComponent>
          </div>
        </section>
      </div>
    </>
  );
}
