import React, { useEffect } from "react";
import AppLayout from "./routers";
import callApi from "./services/api/apiClient";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setupNotifications } from "./firebase/firebase";
import {
  toastNotification,
  sendNativeNotification,
} from "./helper/notificationHelpers";
import Message from "./components/Message";
import { dispatchStore } from "./lib/dispatchStore";
import { liveFetchBoolean, notiCountSlice, notiSlice } from "./helper/customSlice";

const App = () => {
  const setting = useSelector((state) => state?.others?.setting?.data?.data);
  const AccessToken = useSelector((state) => state.AccessToken);

  // onMessage(messaging, (payload) => {
  //   toast(<Message notification={payload.notification} />);
  // });

  useEffect(() => {
    callApi("others/setting").executeDispatch();
    setupNotifications((payload) => {
      console.log("hello wworld?", payload);
      if (AccessToken) {
        // Ensure page is greater than 0 to avoid unnecessary API calls
        callApi("notification/list")
          .withKeyParameter({ limit: 30, page: 1 })
          .loadingGroup("notification/list")
          .execute()
          .then((res) => {
            if (res?.status_code === 200) {
              console.log("Success fetching notifications:", res?.data?.data);
              dispatchStore(notiSlice.actions.setNotiSlice(res?.data?.data));
            } else {
              console.warn("Error fetching notifications:", res);
            }
          });
        dispatchStore(notiCountSlice.actions.plusNotiCount());
        dispatchStore(liveFetchBoolean.actions.setLiveFetchBoolean())
      }
      toast(<Message notification={payload?.notification} />);
      toastNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    });
  }, []);

  useEffect(() => {
    if (setting?.site_favicon) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = setting?.site_favicon;
    }
    if (setting?.site_name) {
      document.title = setting?.site_name;
    }
  }, [setting]);

  return (
    <>
      <AppLayout />
      <ToastContainer />
    </>
  );
};

export default App;
