import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import {
  ArrowLeftIcon,
  StarIcon,
  PencilSquareIcon,
  PlusIcon,
  MinusIcon,
  HomeIcon,
  ShoppingCartIcon,
  GiftIcon,
} from "@heroicons/react/24/solid";
import { CheckIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import AuthHeader from "../../layout/AuthHeader";
import BottomNav from "../../layout/BottomNav";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";

export default function OrderSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};

  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const paymentlist = useSelector(
    (state) => state?.cart?.paymentlist?.data?.data
  );

  useEffect(() => {
    callApi("cart/paymentlist").executeDispatch();
  }, []);

  useEffect(() => {
    callApi("history/order").executeDispatch();
  }, []);

  return (
    <>
      <AuthHeader title={getLanguageFile.order_success} backkey={true} />

      <div
        id="body"
        className="auth bottombar"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <section className="container p-lr-16 m-b-16">
          <div className="flex flex-col">
            {/* Order Success Section */}
            <div className="flex flex-col items-center m-t-24 m-b-16 bg-white">
              <div className="bg-green-500 rounded-full p-4">
                <CheckIcon className="flex w-[24px] h-[24px] m-all-5 justify-center items-center shrink-0 font-bold text-white" />
              </div>
              <p className="fs-16 font-semibold text-gray-800 m-tb-12">
                {getLanguageFile.order_success}
              </p>
              <p className="fs-12 text-gray-600 w-[200px] text-center">
                {getLanguageFile.order_confirm_and_continue_shipping1}
              </p>
              <p className="fs-12 text-gray-600 w-[220px] text-center">
                {getLanguageFile.order_confirm_and_continue_shipping2}
              </p>
            </div>

            {/* Order Details Section */}
            <div className="px-2 pt-4 m-b-16 bg-foundation-grey-light-hover rounded-lg">
              <h2 className="fs-14 font-bold text-gray-800 m-b-16">
                {getLanguageFile.order_detail}
              </h2>
              <div className="">
                <p className="flex justify-between">
                  <span className="fs-12 text-gray-600 m-b-16">
                    {getLanguageFile.order_id}
                  </span>
                  <span className="fs-12 font-semibold text-gray-800 m-b-16">
                    {data?.data?.order_id}
                  </span>
                </p>
                {/* <p className="flex justify-between">
                  <span className="fs-12 text-gray-600 m-b-16">
                    {getLanguageFile.transition_id}
                  </span>
                  <span className="fs-12 font-semibold text-gray-800 m-b-16">
                    {data?.data?.order_number}
                  </span>
                </p> */}
                <p className="flex justify-between">
                  <span className="fs-12 text-gray-600 m-b-16">
                    {getLanguageFile.order_date}
                  </span>
                  <span className="fs-12 font-semibold text-gray-800 m-b-16">
                    {/* {new Date(data?.data?.created_at).toLocaleDateString(
                      "en-GB",
                      { day: "2-digit", month: "long", year: "numeric" }
                    )} */}
                    {data?.data?.order_date}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="fs-12 text-gray-600 m-b-16">
                    {getLanguageFile.shipping_address}
                  </span>
                  <span className="fs-12 text-gray-800 m-b-16">
                    {/* 20 street example township <br /> yangon myanmar 4001 */}
                    {data?.data?.shipping_address}
                  </span>
                </p>
                {/* <p className="flex justify-between">
                  <span className="fs-12 text-gray-600 m-b-16">
                    {getLanguageFile.estimated_arrival_time}
                  </span>
                  <span className="fs-12 font-semibold text-gray-800 m-b-16">
                    2 to 3 days
                  </span>
                </p> */}
              </div>
            </div>

            {/* Order Items Section */}
            <div className="px-2 pt-4 m-b-16 bg-foundation-grey-light-hover rounded-lg">
              <h2 className="fs-16 font-bold text-gray-800 m-b-16">
                { getLanguageFile.success_order_item }
              </h2>
              <div className="mt-2">
                {data?.data?.order_items?.map((item, key) => (
                  <p key={key} className="flex justify-between">
                    <span className="fs-12 text-gray-600 m-b-16">
                      {/* Item {key} */}
                      {item?.product?.name}
                      {" x "}
                      {item?.qty}
                    </span>
                    {/* <span className="fs-12 font-semibold text-gray-800 m-b-16">
                      {item?.product?.name}
                      {" x "}
                      {item?.qty}
                    </span> */}
                  </p>
                ))}
              </div>
            </div>

            {/* Payment Detail Section */}
            <div className="px-2 pt-4 m-b-16 bg-foundation-grey-light-hover rounded-lg">
              <h2 className="fs-16 font-bold text-gray-800 m-b-16">
                {getLanguageFile.payment_detail}
              </h2>
              <div className="mt-2">
                <p className="flex justify-between">
                  <span className="fs-12 text-gray-600 m-b-16">
                    {getLanguageFile.payment_method}
                  </span>
                  <span className="fs-12 font-semibold text-gray-800 m-b-16">
                    {/* {
                      paymentlist?.find(
                        (item) => item?.id == data?.data?.payment_id
                      )?.name
                    } */}
                    {data?.data?.payment}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="fs-12 text-gray-600 m-b-16">
                    {getLanguageFile.pay_date}
                  </span>
                  <span className="fs-12 font-semibold text-gray-800 m-b-16">
                    {/* {new Date(data?.data?.created_at).toLocaleDateString(
                      "en-GB",
                      { day: "2-digit", month: "long", year: "numeric" }
                    )} */}
                    {data?.data?.order_date}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="fs-12 text-gray-600 m-b-16">
                    {getLanguageFile.total_amount}
                  </span>
                  <span className="fs-12 font-semibold text-gray-800 m-b-16">
                    MMK {data?.data?.total_price}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Ship the Orders Button */}
        <div className="w-100 p-lr-16 max-w-[393px] sm:max-w-[none] bottom-0 bg-white ">
          <div className="grid grid-cols-1 gap-2">
            <button
              onClick={() => navigate("/lucky-draw-all-prize")}
              className="flex items-center justify-center m-tb-16 bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100"
            >
              { getLanguageFile.go_back_lucky_draw }
            </button>
          </div>
        </div>
      </div>

      <BottomNav active={1} getLanguageFile={getLanguageFile} />
    </>
  );
}
