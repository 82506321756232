import { ArrowRightEndOnRectangleIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PWAInstalllationAlert = ({ text, route, btnLabel, type, onClick, cancel = false, close }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    route ? navigate(route) : onClick();
  };
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  return (
    <div className="fixed w-full h-screen bg-[#333333e5] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className="w-[90%] md:w-[450px] min-h-[150px] bg-white border-t-[10px] border-red-1 rounded-[5px] p-tb-16 p-lr-24 relative text-center">
        <div className=" flex justify-center">
          {text ?
            <div className="flex flex-col items-center gap-[24px] text-500 font-bold">
              <>
                    <ExclamationCircleIcon className="flex w-[32px] h-[32px] font-extrabold justify-center items-center shrink-0 text-green-500" />
                    <span className="fs-15 font-semibold">Install Lucky Mall?</span>
                  </>
            </div>
            :
            <ArrowRightEndOnRectangleIcon className="flex w-[32px] h-[32px] font-extrabold justify-center items-center shrink-0 text-300" />
          }
        </div>
        <p className={`my-[8px] ${type === "message" || type === "error" || type === "warning" || type === "success" ? 'fs-12' : 'fs-16'} font-normal text-500`}>
          {text || "You need to login!"}{" "}
        </p>

        <div className="flex gap-2">

          {
            cancel &&
            <button
              onClick={close}
              className="flex items-center justify-center border-[1px] fs-15 border-gray-500 hover:border-gray-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-24 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50">
              {cancel}
            </button>
          }
          <button
            onClick={handleNavigate}
            className={`flex items-center justify-center border-[1px] fs-15 ${cancel && 'bg-gold-500'} border-gold-500 hover:bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-24 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50`}>
            {btnLabel}
          </button>
        </div>

      </div>
    </div>
  );
};

export default PWAInstalllationAlert;
