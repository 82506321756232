import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthHeader from "../../layout/AuthHeader";
import Avatar from "../../assets/icons/Avatar.png";
import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { ChatBubbleBottomCenterIcon, GiftIcon, QuestionMarkCircleIcon, TruckIcon } from "@heroicons/react/24/outline";
import BottomNav from "../../layout/BottomNav";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import LanguageModal from "./LanguageModal";
import mm from "../../assets/images/flags/mm.svg";
import en from "../../assets/images/flags/en.svg";
import ch from "../../assets/images/flags/ch.svg";

export default function ProfilePage() {
  const navigate = useNavigate();
  const profile = useSelector((state) => state?.profile?.profile?.data?.data);
  const AccessToken = useSelector((state) => state.AccessToken);

  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const [showBuyTicketModal, setShowBuyTicketModal] = useState(false);

  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
    setShowBuyTicketModal(false);
  };

  useEffect(() => {
    if (AccessToken) {
      callApi("profile/profile").executeDispatch();
    }
  }, [AccessToken]);
  const flagCircleLayout = {
    width: "20px",
    height: "20px",
    objectFit: "cover",
    borderRadius: "50%",
  };
  return (
    <>
      <div
        id="body"
        className="bottombar noheader"
        style={{ overflow: "hidden auto", padding: "16px" }}
      >
        <section className="container m-b-16">
          <button
            onClick={() => navigate("/account-detail")}
            className="flex justify-between items-center w-full"
          >
            <div className="flex items-center">
              <img
                src={profile?.profile_image}
                alt={profile?.name}
                className="flex w-[64px] items-center gap-[4px] me-2 rounded-full"
              />
              <div className="flex flex-col">
                <span className="text-500 fs-16 font-semibold text-start">
                  {profile?.name}
                </span>

                <span className="text-500 fs-12 font-normal text-start">
                  {profile?.phone}
                </span>
              </div>
            </div>

            <div>
              <ChevronRightIcon className="flex w-[24px] h-[24px] justify-center items-center shrink-0 text-500" />
            </div>
          </button>

          <div className="border-t-2 border-Light/active my-4"></div>

          <div className="flex flex-col">
            <button
              onClick={() => navigate("/my-orders-to-ship")}
              className="flex justify-between items-center py-2 mb-2"
            >
              <div className="flex items-center">
                <TruckIcon className="flex w-[24px] h-[24px] me-2 justify-center items-center shrink-0 text-500" />
                <span className="text-300 fs-14 font-normal">
                  {getLanguageFile.my_order}
                </span>
              </div>
              <ChevronRightIcon className="flex w-[20px] h-[20px] justify-center items-center shrink-0 text-500" />
            </button>

            <button
              onClick={() => navigate("/onboarding")}
              className="flex justify-between items-center py-2 mb-2"
            >
              <div className="flex items-center">
                <QuestionMarkCircleIcon className="flex w-[24px] h-[24px] me-2 justify-center items-center shrink-0 text-500" />
                <span className="text-300 fs-14 font-normal">
                  {getLanguageFile.user_guide}
                </span>
              </div>
              <ChevronRightIcon className="flex w-[20px] h-[20px] justify-center items-center shrink-0 text-500" />
            </button>

            <button
              onClick={() => navigate("/my-reviews")}
              className="flex justify-between items-center py-2 mb-2"
            >
              <div className="flex items-center">
                <ChatBubbleBottomCenterIcon className="flex w-[24px] h-[24px] me-2 justify-center items-center shrink-0 text-500" />
                <span className="text-300 fs-14 font-normal">
                  {getLanguageFile.my_reviews}
                </span>
              </div>
              <ChevronRightIcon className="flex w-[20px] h-[20px] justify-center items-center shrink-0 text-500" />
            </button>

            <button
              onClick={() => navigate("/referral-gift")}
              className="flex justify-between items-center py-2 mb-2"
            >
              <div className="flex items-center">
                <GiftIcon className="flex w-[24px] h-[24px] me-2 justify-center items-center shrink-0 text-500" />
                <span className="text-300 fs-14 font-normal">
                  Referral Gifts
                </span>
              </div>
              <ChevronRightIcon className="flex w-[20px] h-[20px] justify-center items-center shrink-0 text-500" />
            </button>

            <button
              onClick={() => setShowBuyTicketModal(true)}
              className="flex justify-between items-center py-2 mb-2"
            >
              <div className="flex items-center">
                <img
                  className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
                  src={
                    window.localStorage.currentLanguage == "en"
                      ? en
                      : window.localStorage.currentLanguage == "mm"
                      ? mm
                      : ch
                  }
                  style={flagCircleLayout}
                />
                {/* {changeLanguage == "mm" ? (
                  <svg
                    className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_322_5657)">
                      <path d="M0 0H20V20H0V0Z" fill="#FECB00" />
                      <path
                        d="M0 6.66675H20V20.0001H0V6.66675Z"
                        fill="#34B233"
                      />
                      <path
                        d="M0 13.3333H20V19.9999H0V13.3333Z"
                        fill="#EA2839"
                      />
                      <path
                        d="M6.2196 15.5047L7.96652 8.86439L12.2463 11.8549L6.2196 15.5047Z"
                        fill="white"
                      />
                      <path
                        d="M3.81737 8.39435L10.9237 7.94023L9.289 12.779L3.81737 8.39435Z"
                        fill="white"
                      />
                      <path
                        d="M10.1064 4L12.7514 10.3597H7.4613L10.1064 4Z"
                        fill="white"
                      />
                      <path
                        d="M16.3954 8.39447L10.9237 12.7791L9.28901 7.94035L16.3954 8.39447Z"
                        fill="white"
                      />
                      <path
                        d="M13.9932 15.5048L7.96645 11.8549L12.2462 8.86442L13.9932 15.5048Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_322_5657">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="10" r="10" fill="#B22234" />
                    <mask
                      id="mask0"
                      mask-type="alpha"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                    >
                      <circle cx="10" cy="10" r="10" fill="white" />
                    </mask>
                    <g mask="url(#mask0)">
                      <path d="M0 2H20V4H0V2Z" fill="white" />
                      <path d="M0 6H20V8H0V6Z" fill="white" />
                      <path d="M0 10H20V12H0V10Z" fill="white" />
                      <path d="M0 14H20V16H0V14Z" fill="white" />
                      <path d="M0 18H20V20H0V18Z" fill="white" />
                      <path d="M0 0H20V2H0V0Z" fill="#B22234" />
                      <path d="M0 4H20V6H0V4Z" fill="#B22234" />
                      <path d="M0 8H20V10H0V8Z" fill="#B22234" />
                      <path d="M0 12H20V14H0V12Z" fill="#B22234" />
                      <path d="M0 16H20V18H0V16Z" fill="#B22234" />
                      <path d="M0 0H10V10H0V0Z" fill="#3C3B6E" />
                      <path
                        d="M1 1.5L1.30902 2.40451H2.23607L1.46353 2.84549L1.77254 3.75L1 3.30902L0.227458 3.75L0.536471 2.84549L-0.236068 2.40451H0.690983L1 1.5Z"
                        fill="white"
                      />
                      <path
                        d="M3 1.5L3.30902 2.40451H4.23607L3.46353 2.84549L3.77254 3.75L3 3.30902L2.22746 3.75L2.53647 2.84549L1.76393 2.40451H2.69098L3 1.5Z"
                        fill="white"
                      />
                      <path
                        d="M5 1.5L5.30902 2.40451H6.23607L5.46353 2.84549L5.77254 3.75L5 3.30902L4.22746 3.75L4.53647 2.84549L3.76393 2.40451H4.69098L5 1.5Z"
                        fill="white"
                      />
                      <path
                        d="M7 1.5L7.30902 2.40451H8.23607L7.46353 2.84549L7.77254 3.75L7 3.30902L6.22746 3.75L6.53647 2.84549L5.76393 2.40451H6.69098L7 1.5Z"
                        fill="white"
                      />
                      <path
                        d="M9 1.5L9.30902 2.40451H10.2361L9.46353 2.84549L9.77254 3.75L9 3.30902L8.22746 3.75L8.53647 2.84549L7.76393 2.40451H8.69098L9 1.5Z"
                        fill="white"
                      />
                      <path
                        d="M1 4.5L1.30902 5.40451H2.23607L1.46353 5.84549L1.77254 6.75L1 6.30902L0.227458 6.75L0.536471 5.84549L-0.236068 5.40451H0.690983L1 4.5Z"
                        fill="white"
                      />
                      <path
                        d="M3 4.5L3.30902 5.40451H4.23607L3.46353 5.84549L3.77254 6.75L3 6.30902L2.22746 6.75L2.53647 5.84549L1.76393 5.40451H2.69098L3 4.5Z"
                        fill="white"
                      />
                      <path
                        d="M5 4.5L5.30902 5.40451H6.23607L5.46353 5.84549L5.77254 6.75L5 6.30902L4.22746 6.75L4.53647 5.84549L3.76393 5.40451H4.69098L5 4.5Z"
                        fill="white"
                      />
                      <path
                        d="M7 4.5L7.30902 5.40451H8.23607L7.46353 5.84549L7.77254 6.75L7 6.30902L6.22746 6.75L6.53647 5.84549L5.76393 5.40451H6.69098L7 4.5Z"
                        fill="white"
                      />
                      <path
                        d="M9 4.5L9.30902 5.40451H10.2361L9.46353 5.84549L9.77254 6.75L9 6.30902L8.22746 6.75L8.53647 5.84549L7.76393 5.40451H8.69098L9 4.5Z"
                        fill="white"
                      />
                      <path
                        d="M1 7.5L1.30902 8.40451H2.23607L1.46353 8.84549L1.77254 9.75L1 9.30902L0.227458 9.75L0.536471 8.84549L-0.236068 8.40451H0.690983L1 7.5Z"
                        fill="white"
                      />
                      <path
                        d="M3 7.5L3.30902 8.40451H4.23607L3.46353 8.84549L3.77254 9.75L3 9.30902L2.22746 9.75L2.53647 8.84549L1.76393 8.40451H2.69098L3 7.5Z"
                        fill="white"
                      />
                      <path
                        d="M5 7.5L5.30902 8.40451H6.23607L5.46353 8.84549L5.77254 9.75L5 9.30902L4.22746 9.75L4.53647 8.84549L3.76393 8.40451H4.69098L5 7.5Z"
                        fill="white"
                      />
                      <path
                        d="M7 7.5L7.30902 8.40451H8.23607L7.46353 8.84549L7.77254 9.75L7 9.30902L6.22746 9.75L6.53647 8.84549L5.76393 8.40451H6.69098L7 7.5Z"
                        fill="white"
                      />
                      <path
                        d="M9 7.5L9.30902 8.40451H10.2361L9.46353 8.84549L9.77254 9.75L9 9.30902L8.22746 9.75L8.53647 8.84549L7.76393 8.40451H8.69098L9 7.5Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )} */}

                <span className="text-300 fs-14 font-normal">
                  {getLanguageFile.language_type} :{" "}
                  {getLanguageFile.language_name}
                </span>
              </div>
              <ChevronRightIcon className="flex w-[20px] h-[20px] justify-center items-center shrink-0 text-500" />
            </button>
          </div>
        </section>
      </div>

      <BottomNav active={4} getLanguageFile={getLanguageFile} />

      <LanguageModal
        show={showBuyTicketModal}
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
        onClose={() => setShowBuyTicketModal(false)}
      />
    </>
  );
}
