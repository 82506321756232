import React, { useRef, useState } from "react";
import { StarIcon, XMarkIcon } from "@heroicons/react/24/solid"; // Importing StarIcon from Heroicons
import callApi from "../../services/api/apiClient";
import debounce from "../../helper/debounce";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReviewModal = ({
  productDetail,
  visible,
  onClose,
  setAlertmessage,
  setServerError,
}) => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const AccessToken = useSelector((state) => state.AccessToken);

  const [rating, setRating] = useState(0); // Rating value from 0 to 5
  const [comment, setComment] = useState(""); // Comment text
  const [reviewImage, setReviewImage] = useState([]);
  const [reviewImageWarning, setReviewImageWarning] = useState(false);

  // Function to handle rating by clicking stars
  const handleRating = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleFileChange = async (event) => {
    if (reviewImage?.length < 5) {
      const file = event.target.files[0];
      if (!file) {
        // Store the selected file in a ref
        setServerError("Please select Payment Image");
        return;
      }
      setReviewImage([...reviewImage, file]);
    } else {
      setReviewImageWarning(true);
      debounce(() => {
        setReviewImageWarning(false);
      }, 3000)();
    }
  };

  function handleRemoveReviewImage(index) {
    setReviewImage(reviewImage.filter((_, i) => i !== index));
  }

  // Function to post the review
  const postReview = () => {
    if (productDetail) {
      callApi("rating/product")
        .withBody({
          product_id: productDetail?.id,
          rating: rating,
          comment: comment,
        })
        .execute()
        .then((res) => {
          if (res?.status_code == 200) {
            callApi("products/productDetail")
              .withHeaders({
                "X-localization": window.localStorage.currentLanguage,
              })
              .withKeyParameter({ productSlug: productDetail?.slug })
              .loadingGroup("productDetail")
              .executeDispatch();
            // callApi("review/getAll").executeDispatch();
            // callApi("review/getuserReview").executeDispatch();
            setAlertmessage("Your review has been uploaded");
            onClose();
          } else {
            setServerError(res?.message || "Unexpected error occured");
          }
        });
    } else {
      const formData = new FormData();

      formData.append("rating", rating);
      formData.append("comment", comment);
      formData.append("file", reviewImage);

      callApi("review/post")
        .withHeaders({ "Content-Type": "multipart/form-data" })
        .withBody(formData)
        .execute()
        .then((res) => {
          if (res?.status_code == 200) {
            callApi("review/getAll").executeDispatch();
            callApi("review/getuserReview").executeDispatch();
            setAlertmessage("Your review has been uploaded");
            onClose();
          } else {
            setServerError(res?.message || "Unexpected error occured");
          }
        });
    }
  };

  if (!visible) return null; // Do not render the modal if not visible

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
      <div className="relative bg-white p-6 rounded-lg shadow-lg w-96 text-center">
        {/* Close Button */}
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 transition duration-200"
          onClick={onClose}
        >
          <XMarkIcon className="w-6 h-6" />
        </button>

        <h2 className="text-xl font-bold mb-4">Rate and Review</h2>

        {!productDetail && (
          <>
            <div className="flex">
              <label className="text-black fs-12 cursor-pointer bg-gold-500 py-3 px-4 rounded-lg ms-2">
                {reviewImage?.length > 0 && reviewImage?.length <= 5
                  ? "Add More"
                  : "Browse"}
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="hidden"
                  // style={{ display: "none" }}
                />
              </label>
            </div>

            <div className="flex">
              {reviewImageWarning ? (
                <h2 className="text-red-500 py-1">Max 5 Images!</h2>
              ) : (
                <h2 className="text-red-500 py-1 opacity-0">Max 5 Images!</h2>
              )}
            </div>

            <div className="flex flex-wrap">
              {reviewImage?.length > 0 &&
                reviewImage?.map((eachReviewImage, index) => (
                  <div key={index} className="relative">
                    <button
                      className="absolute top-1 right-1 text-white bg-red-500 rounded-full hover:bg-red-600 transition duration-200"
                      onClick={() => handleRemoveReviewImage(index)}
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </button>
                    <img
                      src={URL.createObjectURL(eachReviewImage)}
                      alt="Selected"
                      className="max-h-20 rounded-lg border-2 border-gray-300 p-2"
                    />
                  </div>
                ))}
            </div>
          </>
        )}

        {/* Rating Stars */}
        <div className="flex justify-center mb-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              onClick={() => handleRating(star)}
              className="focus:outline-none"
            >
              <StarIcon
                id="StarIcon"
                className={`w-8 h-8 ${
                  star <= rating ? "text-yellow-500" : "text-gray-400"
                }`}
              />
            </button>
          ))}
        </div>

        {/* Comment Box */}
        <textarea
          className="w-full h-24 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          placeholder="Write your comment here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />

        {/* Post Button */}
        <button
          className="bg-gold-500 text-500 py-2 px-4 rounded-full font-semibold fs-14 transition duration-200"
          onClick={() => (AccessToken ? postReview() : navigate("/signin"))}
        >
          Post Review
        </button>
      </div>
    </div>
  );
};

export default ReviewModal;
