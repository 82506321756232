import React, { useEffect, useRef, useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import AuthHeader from "../../layout/AuthHeader";
import { useLocation, useNavigate } from "react-router-dom";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import { slice } from "../../redux/reducers/reducer";
import { dispatchStore } from "../../lib/dispatchStore";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";

export default function ConfirmShipping() {
  const navigate = useNavigate();

  const location = useLocation();
  const { selectedItems } = location.state || {};
  console.log(selectedItems, "selectedItems props");

  const fileInputRef = useRef(null);
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const [serverError, setServerError] = useState(null);
  const [useCardNextTime, setUseCardNextTime] = useState(false);
  const [shipToDifferentLocation, setShipToDifferentLocation] = useState(false);
  const [totalPrices, setTotalPrices] = useState(null);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [paymentImage, setPaymentImage] = useState(null);
  const [shippingOrderList, setShippingOrderList] = useState(null);

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const AccessToken = useSelector((state) => state.AccessToken);
  const profile = useSelector((state) => state?.profile?.profile?.data?.data);
  const shippingPrice = useSelector(
    (state) => state?.shipping?.shipPrice?.data?.data
  );
  const paymentlist = useSelector(
    (state) => state?.cart?.paymentlist?.data?.data
  );
  const paymentdetail = useSelector(
    (state) => state?.cart?.paymentdetail?.data?.data
  );
  const orderList = useSelector((state) => state?.history?.order?.data?.data);

  const toggleUseCardNextTime = () => {
    setUseCardNextTime(!useCardNextTime);
  };

  const toggleShipToDifferentLocation = () => {
    setShipToDifferentLocation(!shipToDifferentLocation);
  };

  const handleConfirmShipping = () => {
    if (selectedPaymentOption) {
      // fileInputRef.current.click();
      if (paymentImage) {
        const formData = new FormData();
        console.log(
          JSON.stringify([...selectedItems]),
          selectedPaymentOption,
          "[...selectedItems]"
        );
        formData.append("order_item_id", JSON.stringify([...selectedItems]));
        formData.append("payment_id", selectedPaymentOption);
        formData.append("payment_screenshot", paymentImage);

        callApi("shipping/shipOrder")
          .withBody(formData)
          .executeDispatch()
          .then((res) => {
            if (res?.status_code == 200) {
              dispatchStore(
                slice?.cart?.actions.clearSliceElement("paymentdetail")
              );
              setPaymentImage(null);
              setSelectedPaymentOption(null);
              navigate("/shipping-order-success", { state: { data: res } });
            } else {
              if (res?.message) {
                setServerError(res?.message);
              }
            }
          });
      } else {
        setServerError("Please select Payment Image");
      }
    } else {
      setServerError("Please select Payment");
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      // Store the selected file in a ref
      setServerError("Please select Payment Screenshot");
      return;
    }
    setPaymentImage(file);
  };

  useEffect(() => {
    callApi("cart/paymentlist").executeDispatch();
    dispatchStore(slice?.cart?.actions.clearSliceElement("paymentdetail"));
  }, []);

  useEffect(() => {
    if (AccessToken) {
      callApi("history/order").executeDispatch();
      callApi("profile/profile").executeDispatch();
    }
  }, [AccessToken]);

  useEffect(() => {
    if (selectedPaymentOption) {
      callApi("cart/paymentdetail")
        .withKeyParameter({ paymentID: selectedPaymentOption })
        .executeDispatch();
    }
  }, [selectedPaymentOption]);

  useEffect(() => {
    if (selectedItems) {
      callApi("shipping/shipPrice")
        .withBody({ order_item_id: JSON.stringify([...selectedItems]) })
        .executeDispatch();

      let shiporder = orderList?.filter((obj) =>
        Array.from(selectedItems).includes(obj.id)
      );
      const TempCalculation = shiporder?.reduce(
        (acc, item) => acc + item?.product?.actual_price * item?.qty,
        0
      );
      setShippingOrderList(shiporder);
      setTotalPrices(TempCalculation);
    }
  }, [selectedItems]);

  return (
    <>
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}

      <AuthHeader title={getLanguageFile.confirm_shipping} backkey={true} />

      <div
        id="body"
        className="auth bottombutton"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        {/* Section 1: Shipping Info */}
        <section className="px-4 mb-4">
          <div className="mb-2">
            <h2 className="fs-14 font-bold">
              {getLanguageFile.shipping_info}
              <span className="text-red-500">*</span>
            </h2>
          </div>
          <div
            className={`p-4 rounded-lg bg-foundation-grey-light-hover border-[1px] border-Light/active ${
              shipToDifferentLocation && "opacity-60"
            }`}
          >
            <p className="font-extrabold fs-12 mb-1">{profile?.name}</p>
            <p className="text-500 font-normal fs-12 mb-1">{profile?.phone}</p>
            <p className="flex justify-between">
              <span className="fs-12 text-gray-600 m-b-4">
                {profile?.address +
                  ", " +
                  profile?.township?.name +
                  ", " +
                  profile?.region?.name}
              </span>
            </p>
          </div>
          {/* <div className="mt-2">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={shipToDifferentLocation}
                onChange={toggleShipToDifferentLocation}
              />
              <span className="ml-2 text-500 fs-12">
                {getLanguageFile.ship_diff_location}?
              </span>
            </label>
          </div> */}
        </section>

        {/* Additional Shipping Info if "Ship to different location" is checked */}
        {shipToDifferentLocation && (
          <section className="px-4">
            <div className="mb-4">
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.receiver_name}{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="Enter receiver name"
                  />
                </div>
                <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.phone_number}{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="Enter phone number"
                  />
                </div>
                <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.township}{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="Botahtaung"
                  />
                </div>
                <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.street_address}Street Address{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="43 street"
                  />
                </div>
                <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.phone_number}Postal code / ZIP
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="Add postal code"
                  />
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Section 2: Shipping Fee */}
        <section className="px-4 m-b-24">
          <div className="bg-foundation-grey-light-hover p-4 rounded-lg">
            <div className="flex justify-between mb-4">
              <p className="text-500 font-normal fs-12">
                {getLanguageFile.shipping_item} ({shippingOrderList?.length})
              </p>
              {/* <p className="text-500 font-semibold fs-12">MMK{shippingPrice}</p> */}
            </div>
            {shippingOrderList?.map((item, key) => (
              <div key={key}>
                <div className="flex relative">
                  <div href="product-detail" className="w-1/5 relative">
                    <img
                      src={
                        item?.product?.feature_image &&
                        item?.product?.feature_image
                      }
                      alt="Slide 1"
                      className=""
                    />
                  </div>

                  <div className="flex-grow content-center w-4/5 p-lr-8 p-tb-16">
                    <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                      {item?.product?.name}
                    </div>

                    <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                      Quantity: {item?.qty}
                    </div>
                  </div>
                </div>

                <div className="border-t-2 border-Light/active mb-4"></div>
              </div>
            ))}
            {/* <div className="flex justify-between mb-4">
              <p className="text-500 font-normal fs-12">
                {getLanguageFile.tax}
              </p>
              <p className="text-500 font-semibold fs-12">MMK2,000</p>
            </div> */}
            <div className="flex justify-between mt-4 border-t pt-4">
              <p className="text-500 font-semibold fs-12">
                {getLanguageFile.total_shipping_fee}
              </p>
              <p className="text-500 font-bold fs-12">MMK{shippingPrice}</p>
            </div>
          </div>
        </section>

        {/* Section 3: Payment Methods */}
        <section className="px-4 mb-4">
          <div className="mb-2">
            <h2 className="fs-14 font-bold">
              {getLanguageFile.payment_method}{" "}
              <span className="text-red-500">*</span>
            </h2>
          </div>
          <div className="border border-gray-300 rounded-lg p-4">
            <div className="mb-0">
              <div className="flex items-center space-x-4 mb-0">
                {paymentlist?.map((item, key) => (
                  <label key={key} className="inline-flex items-center">
                    <input
                      type="radio"
                      name="paymentMethod"
                      className="form-radio"
                      value={item?.id}
                      checked={selectedPaymentOption == item?.id}
                      onChange={(e) => setSelectedPaymentOption(e.target.value)}
                    />
                    {item?.logo ? (
                      <img
                        className="w-[auto] h-[20px] ms-1"
                        src={item?.logo}
                        alt={item?.name}
                      />
                    ) : (
                      <span className="ml-2 text-500 fs-12">{item?.name}</span>
                    )}
                  </label>
                ))}
              </div>
              <div className="grid grid-cols-1 gap-4">
                {paymentdetail?.payment_numbers?.[0]?.account_name && (
                  <div className="text-500 fs-12 mt-4">
                    Merchent name:{" "}
                    {paymentdetail?.payment_numbers?.[0]?.account_name}
                  </div>
                )}
                {paymentdetail?.payment_numbers?.[0]?.account_number && (
                  <div className="text-500 fs-12">
                    Merchent account no:{" "}
                    {paymentdetail?.payment_numbers?.[0]?.account_number}
                  </div>
                )}
                {paymentdetail?.payment_numbers?.[0]?.account_number && (
                  <div className="flex">
                    <div className="text-500 fs-12">Payment ScreenShot: </div>
                    <label className="text-black fs-12 cursor-pointer bg-gold-500 py-3 px-4 rounded-lg ms-2">
                      {paymentImage ? "Reselect" : "Browse"}

                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className="hidden"
                        // style={{ display: "none" }}
                      />
                    </label>
                  </div>
                )}
                {paymentImage && (
                  <img
                    src={URL.createObjectURL(paymentImage)}
                    alt="Selected"
                    className="max-w-full max-h-80 rounded-lg border-2 border-gray-300 p-2"
                  />
                )}
                {/* <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.card_number}
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="number"
                  />
                </div>
                <div>
                  <label className="text-500 fs-12">
                    {getLanguageFile.name_on_card}
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                    placeholder="name"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-500 fs-12">
                      {getLanguageFile.expire_date}
                    </label>
                    <input
                      type="text"
                      className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                      placeholder="MM / YY"
                    />
                  </div>
                  <div>
                    <label className="text-500 fs-12">
                      {getLanguageFile.cvc}
                    </label>
                    <input
                      type="text"
                      className="form-input mt-1 block w-full fs-12 p-2 border border-gray-300 rounded"
                      placeholder="123"
                    />
                  </div>
                </div> */}
              </div>
              {/* <div className="mt-4 flex items-center">
                <button
                  type="button"
                  onClick={toggleUseCardNextTime}
                  className="flex items-center"
                >
                  <div
                    className={`relative inline-block w-12 h-6 align-middle select-none transition duration-200 ease-in-out ${
                      useCardNextTime ? "bg-green-500" : "bg-gray-300"
                    } border border-gray-300 rounded-full`}
                  >
                    <input
                      type="checkbox"
                      name="useCardNextTime"
                      id="useCardNextTime"
                      className="absolute opacity-0 w-0 h-0"
                      checked={useCardNextTime}
                      onChange={toggleUseCardNextTime}
                    />
                    <span
                      className={`absolute left-0 inline-block w-6 h-6 transform bg-white border rounded-full transition-transform duration-200 ease-in-out ${
                        useCardNextTime ? "translate-x-6" : "translate-x-0"
                      }`}
                    ></span>
                  </div>
                  <span className="ml-2 text-500 fs-12 font-medium">
                    {getLanguageFile.use_card_next_time}
                  </span>
                </button>
              </div> */}
            </div>
          </div>

          <div className="text-500 font-bold fs-12 my-2">
            *{getLanguageFile?.payment_method_description1}
            <span className="text-500 font-bold fs-12 my-2">
              {shippingPrice}
            </span>
            {getLanguageFile?.payment_method_description2}
          </div>
        </section>
      </div>

      {/* Confirm Shipping Button */}
      <div className="w-100 p-lr-16 max-w-[393px] sm:max-w-[none] bottom-0 bg-white shadow-md-top">
        <div className="grid grid-cols-1 gap-2">
          <button
            onClick={handleConfirmShipping}
            className="flex items-center justify-center m-tb-16 bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100"
          >
            Confirm Shipping
          </button>
        </div>
      </div>
    </>
  );
}
