import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { RouteConfig } from "./router-config";
import { useSelector } from "react-redux";

export const SpecialAccessRoute = ({ children, special_access, path }) => {
  const AccessToken = useSelector(state => state.AccessToken);

  if (special_access == "user") {
    if (AccessToken) {
      return children
    }
    else{
      return <Navigate to="/signin" />;
    }
  }

  if (special_access == "category") {
    const params = new URLSearchParams(window.location.search);
    const category_id = params.get('category_id');
    if (category_id == null || category_id == undefined || category_id == "") {
      return <Navigate to="/" />;
    } 
    else{
      return children
    }
  }

  if (special_access == "productdetail") {
    const params = new URLSearchParams(window.location.search);
    const product_id = params.get('id');
    if (product_id == null || product_id == undefined || product_id == "") {
      return <Navigate to="/" />;
    } 
    else{
      return children
    }
  }

  return children;
};

const RouteList = () => {
  return (
    <Routes>
      {RouteConfig.map(({ path, element, special_access }, key) => {
        return (
          <Route
            path={path}
            key={key}
            element={
              special_access ? (
                <SpecialAccessRoute special_access={special_access} path={path}>{element}</SpecialAccessRoute>
              ) : (
                element
              )
            }
          />
        );
      })}
    </Routes>
  )
}

export default RouteList