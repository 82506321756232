import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import BottomNav from '../../layout/BottomNav';
import AuthHeader from '../../layout/AuthHeader';
import { useLocation, useNavigate } from 'react-router-dom';

export default function TicketBuyingSuccess() {
  let navigate = useNavigate();
  const [changeLanguage, setChangeLanguage] = React.useState(localStorage.getItem('currentLanguage') || 'en');
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };
  const location = useLocation();
  const { data } = location.state || {};

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  React.useEffect(() => {
    localStorage.setItem('currentLanguage', changeLanguage);
  }, [changeLanguage]);

  return (
    <>
      <AuthHeader title={getLanguageFile.ticket_buying_success} backkey={false} />

      <div id="body" className="auth bottombar" style={{ overflow: 'hidden auto', marginTop: '10px' }}>
        <div className="container p-lr-16">
          {/* Ticket Buying Success Header */}
          <section className="flex flex-col items-center mt-4">
            <CheckCircleIcon className="w-[50px] h-[50px] text-green-500 mb-4" />
            <h2 className="fs-14 font-bold mb-4 text-center">{getLanguageFile.ticket_buying_success}</h2>
            <div className="bg-gray-100 p-4 rounded-lg w-full max-w-md mb-4">
              <div className="flex justify-between mb-4">
                <p className="fs-12">{getLanguageFile.ticket_count}</p>
                <p className="fs-12 font-semibold">{data?.data?.ticket_counts}</p>
              </div>
              <div className="flex justify-between mb-4">
                <p className="fs-12">{getLanguageFile.token_used}</p>
                <p className="fs-12 font-semibold">{data?.data?.token_use} Tokens</p>
              </div>
              <div className="flex justify-between mb-4">
                <p className="fs-12">{getLanguageFile.lucky_product}</p>
                <p className="fs-12 font-semibold">{data?.data?.product_name}</p>
              </div>
              <div className="flex justify-between mb-4">
                <p className="fs-12">{getLanguageFile.my_ticket_number}</p>
                <p className="fs-12 font-semibold">{data?.data?.tickets}</p>
              </div>
            </div>
            <button onClick={()=> navigate('/lucky-draw', { state: { data: "history" } })} className="bg-yellow-400 text-500 font-semibold rounded-full px-4 py-3 w-full max-w-md mb-4">
              {getLanguageFile.view_your_lucky_product}
            </button>
            <button onClick={()=> navigate('/lucky-draw-all-prize')} className="bg-white border-[1px] border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-3 w-full max-w-md">
              {getLanguageFile.buy_ticket_again}
            </button>
          </section>
        </div>
      </div>

      <BottomNav
        active={1}
        getLanguageFile={getLanguageFile}
      />
    </>
  );
}
