import React, { useState, useEffect } from 'react';
import AuthHeader from '../../layout/AuthHeader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import callApi from '../../services/api/apiClient';
import { useSelector } from 'react-redux';
import AlertPopup from '../../components/AlertPopUp/AlertPopUp';
import { useNavigate } from 'react-router-dom';

const EditShippingInfoSchema = Yup.object().shape({
  region_id: Yup.string()
    .required("State / Region is required"),
  township_id: Yup.string()
    .required("Township is required"),
  address: Yup.string()
    .required("Street Address is required"),
  postal_code: Yup.string()
});

export default function EditShippingInfo() {
  const [changeLanguage, setChangeLanguage] = useState(window.localStorage.currentLanguage || 'en');
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const navigate = useNavigate()
  const regions = useSelector(state => state?.others?.regions?.data?.data);
  const townships = useSelector(state => state?.others?.townships?.data?.data);
  const [serverError, setServerError] = useState(null);
  const AccessToken = useSelector(state => state.AccessToken);
  const profileDataSelector = useSelector(state => state?.profile?.profile?.data?.data);

  const formik = useFormik({
    initialValues: {
      region_id: profileDataSelector?.region?.id ? profileDataSelector?.region?.id : "",
      township_id: profileDataSelector?.township?.id ? profileDataSelector?.township?.id : "",
      address: profileDataSelector?.address ? profileDataSelector?.address : "",
      postal_code: profileDataSelector?.postal_code ? profileDataSelector?.postal_code : "",
    },
    isInitialValid: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: EditShippingInfoSchema,
    onSubmit: (values, actions) => {
      callApi("profile/updateShipping")
        .withBody({
          region_id: values.region_id,
          township_id: values.township_id,
          address: values.address,
          postal_code: values.postal_code,
        })
        .execute()
        .then((res) => {
          if (res?.status_code === 200) {
            actions.resetForm();
            navigate("/account-detail");
          }
          else {
            setServerError(res?.message)
          }
        })
        .catch((err) => console.log("error", err));
      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    callApi("others/regions")
      .withHeaders({"X-localization": window.localStorage.currentLanguage})
      .executeDispatch()
  }, [window.localStorage.currentLanguage]);

  useEffect(() => {
    if (AccessToken) {
      callApi("profile/profile")
        .executeDispatch()
    }
  }, [AccessToken]);

  useEffect(() => {
    if (regions?.length > 0 && !formik.values.region_id) {
        const yangonId = regions?.find(region => region?.name === "Yangon")?.id;
        formik.setFieldValue('region_id', yangonId);
        callApi("others/townships")
          .withKeyParameter({ regionid: yangonId })
          .withHeaders({'X-localization': window.localStorage.currentLanguage})
          .executeDispatch()
    } else if (formik.values.region_id) {
      callApi("others/townships")
        .withKeyParameter({ regionid: formik.values.region_id })
        .withHeaders({'X-localization': window.localStorage.currentLanguage})
        .executeDispatch()
    }
  }, [regions, formik.values.region_id, window.localStorage.currentLanguage]);

  useEffect(() => {
    if (townships?.length > 0 && !formik.values.township_id) {
        formik.setFieldValue('township_id', townships?.[0]?.id);
    }
  }, [townships]);

  const handleClose = () => {
    setServerError(null)
  };

  return (
    <>
      {
        serverError &&
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={handleClose}
        />
      }
      <AuthHeader title={getLanguageFile.edit_shipping_info} backkey={true} />

      <div id="body" className="auth" style={{ overflow: 'hidden auto', marginTop: '10px' }}>

        <form onSubmit={formik.handleSubmit}>
          <section className="container p-lr-16 m-b-8">

            {/* State / Region */}
            <div className="flex flex-col mb-4">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.state_or_region} <span className="text-red-500">*</span>
                {formik.errors.region_id && (
                  <span className="text-red-500">{formik.errors.region_id}</span>
                )}
              </label>
              <select
                id="region_id"
                name="region_id"
                onChange={formik.handleChange}
                value={formik.values.region_id}
                onBlur={formik.handleBlur}
                className="text-gray-500 bg-white p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              >
                {
                  regions?.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </select>
            </div>

            {/* Township */}
            <div className="flex flex-col mb-4">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.township} <span className="text-red-500">*</span>
                {formik.errors.township_id && (
                  <span className="text-red-500">{formik.errors.township_id}</span>
                )}
              </label>
              <select
                id="township_id"
                name="township_id"
                onChange={formik.handleChange}
                value={formik.values.township_id}
                onBlur={formik.handleBlur}
                className="text-gray-500 bg-white p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              >
                {
                  townships?.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </select>
            </div>

            {/* Street Address */}
            <div className="flex flex-col mb-4">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.street_address} <span className="text-red-500">*</span>
                {formik.errors.address && (
                  <span className="text-red-500">{formik.errors.address}</span>
                )}
              </label>
              <input
                id="address"
                name="address"
                type="text"
                placeholder="type street address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                className="text-gray-500 p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              />
            </div>

            {/* Postal code / ZIP */}
            <div className="flex flex-col mb-4">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.postal_code}
                {formik.errors.postal_code && (
                  <span className="text-red-500">{formik.errors.postal_code}</span>
                )}
              </label>
              <input
                id="postal_code"
                name="postal_code"
                type="text"
                placeholder="add post code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postal_code}
                className="text-gray-500 p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              />
            </div>

            {/* Save Button */}
            <button
              type="submit"
              disabled={Object.keys(formik.errors).length > 0}
              className="flex items-center justify-center bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-16 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50"
            >
              {getLanguageFile.save}
            </button>
          </section>
        </form>
      </div>
    </>
  );
}
