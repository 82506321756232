import {
  ArrowRightEndOnRectangleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InviteMore = ({
  text,
  route,
  btnLabel,
  type,
  onClick,
  cancel = false,
  close,
}) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    route ? navigate(route) : onClick();
  };
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const profile = useSelector(state => state?.profile?.profile?.data?.data);
  const referralUrl = `${window.location.origin}/signup?ref=`+profile?.referral_code;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralUrl).then(() => {
      alert('Invitation link copied to clipboard');
    }).catch((err) => {
      console.error('Could not copy text: ', err);
    });;
    close();
  };


  return (
    <div className="fixed w-full h-screen bg-[#333333e5] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className="w-[90%] md:w-[450px] min-h-[150px] bg-white border-t-[10px] border-red-1 rounded-[5px] p-tb-16 p-lr-24 relative text-center">
        <div className=" flex justify-center">
          <div className="flex flex-col items-center gap-[24px] text-red-500 font-bold">
            <ExclamationCircleIcon className="flex w-[32px] h-[32px] font-extrabold justify-center items-center shrink-0 text-orange-500" />
            <span className="fs-20 font-semibold">
            Referral Point Not Enough
            </span>
          </div>
        </div>
        <p className={`my-[8px] fs-14 font-normal text-500 mt-4`}>
          {text || "Invite More people"}{" "}
        </p>

        <div className="flex items-center w-full bg-gray-100 p-2 rounded-md">
            <input
              type="text"
              value={referralUrl}
              readOnly
              className="bg-transparent w-full text-center fs-14 p-2 outline-none"
            />
            <button
              onClick={copyToClipboard}
              className="ml-2 bg-gold-500 text-500 fs-14 p-2 rounded-full"
            >
              Copy
            </button>
          </div>
      </div>
    </div>
  );
};

export default InviteMore;
