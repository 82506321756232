import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {

}

export const notiSlice = createSlice({
  name: 'notiSlice',
  initialState: [],
  reducers: {
    setNotiSlice: (state, action) => {
      if (state.length === 0) {
        return action.payload;
      } else {
        // Otherwise, filter out existing notifications and merge
        const existingIds = new Set(state.map(notification => notification.id));
        const newNotifications = action.payload.filter(notification => !existingIds.has(notification.id));
        state.push(...newNotifications);
      }
    },
  },
});

export const liveFetchBoolean = createSlice({
  name: 'liveFetchBoolean',
  initialState: 0,
  reducers: {
    setLiveFetchBoolean: (state, action) => {
      return state === 0 ? 1 : 0;
    },
  },
});


export const notiCountSlice = createSlice({
  name: 'notiCountSlice',
  initialState: 0,
  reducers: {
    plusNotiCount: (state, action) => {
      return state+1;
    },
  },
});

export default {
  notiSlice,
  notiCountSlice,
  liveFetchBoolean,
}