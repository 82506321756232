import React, { useState, useEffect } from 'react';
import AuthHeader from '../../layout/AuthHeader';
import BottomNav from '../../layout/BottomNav';
import productImage from '../../assets/images/LuckydrawItem1.jpg';
import livebgimg1 from '../../assets/images/livebgimg1.png';
import { useNavigate } from 'react-router-dom';
import YouWinModal from './YouWinModal';
import TryAgainModal from './TryAgainModal';

export default function LuckyDrawLive() {
  const [changeLanguage, setChangeLanguage] = useState(localStorage.getItem('currentLanguage') || 'en');
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  useEffect(() => {
    localStorage.setItem('currentLanguage', changeLanguage);
  }, [changeLanguage]);

  const navigate = useNavigate();

  const [showWinModal, setShowWinModal] = useState(false);
  const [showTryAgainModal, setShowTryAgainModal] = useState(false);

  return (
    <>
      <AuthHeader title="Lucky Draw Live" backkey={true} />

      <div id="body" className="auth bottombar" style={{ overflow: 'hidden auto', marginTop: '10px' }}>
        <div className="container p-lr-16">
          {/* Draw Start In */}
          <section className="flex justify-between items-center mb-4">
            <p className="fs-12 text-gray-500">{getLanguageFile.draw_start_in}</p>
            <div className="flex items-center gap-2">
              <div className="flex flex-col items-center bg-red-50 w-[50px] h-[50px] p-2 rounded-md">
                <p className="fs-16 text-red-500 font-semibold">0</p>
                <p className="fs-11 text-gray-500">{getLanguageFile.days}</p>
              </div>
              <div className="flex flex-col items-center bg-red-50 w-[50px] h-[50px] p-2 rounded-md">
                <p className="fs-16 text-red-500 font-semibold">0</p>
                <p className="fs-11 text-gray-500">{getLanguageFile.hours}</p>
              </div>
              <div className="flex flex-col items-center bg-red-50 w-[50px] h-[50px] p-2 rounded-md">
                <p className="fs-16 text-red-500 font-semibold">10</p>
                <p className="fs-11 text-gray-500">{getLanguageFile.minutes}</p>
              </div>
            </div>
          </section>

          {/* Lucky Number Section */}
          <section
            className="flex flex-col h-[200px] justify-center items-center bg-gold-500 rounded-lg mb-4"
            style={{ backgroundImage: `url(${livebgimg1})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
          >
            {/* <p className="fs-16 text-center font-semibold text-500">Lucky number is</p>
            <p className="fs-50 text-center font-semibold text-500">?</p> */}
          </section>

          {/* Product Details Section */}
          <section className="bg-gray-100 p-4 rounded-lg mb-4">
            <div className="flex items-center mb-4">
              <img src={productImage} alt="Product" className="w-1/3 rounded-md" />
              <div className="ml-4">
                <h3 className="fs-14 font-bold">Washing machine</h3>
                <p className="fs-11">Household refrigerator small double door</p>
                <p className="fs-11">original price</p>
                <p className="fs-11 font-bold">MMK1,560,000</p>
              </div>
            </div>
            <div className="mb-4">
              <p className="fs-11">
                {getLanguageFile.remaining_tickets}: <span>9</span> / 10
              </p>
              <div className="w-full bg-gray-300 h-3 rounded-full">
                <div
                  className="bg-yellow-400 h-3 rounded-full"
                  style={{ width: '90%' }}
                ></div>
              </div>
            </div>
            <div className="flex justify-between items-center mb-4">
              <p className="fs-12">{getLanguageFile.my_ticket_number}</p>
              <div className="flex">
                {[11, 13, 14, 21, 22].map(number => (
                  <span key={number} className="bg-gold-50 text-gold-900 border-[1px] border-gold-200 fs-12 font-semibold rounded-full w-[25px] h-[25px] text-center p-1 mr-1">{number}</span>
                ))}
              </div>
            </div>
          </section>


          {/* Washing Machine Card */}
          {/* <div className="bg-yellow-400 pt-4 px-4 pb-2 rounded-lg mb-4">
            <div className="flex items-center mb-4">
              <img src={productImage} alt="Product" className="w-1/3 rounded-md" />
              <div className="ml-4 relative">
                <h3 className="fs-14 font-bold">Washing machine</h3>
                <p className="fs-11">Household refrigerator small double door</p>
                <p className="fs-11">original price</p>
                <p className="fs-11 font-bold">MMK1,560,000</p>
                <p className="fs-11">Opened at 12/06/2024</p>
                <div className="absolute top-0 right-0 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                  <span className="text-white fs-8 font-medium">You Win!</span>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-300 my-4"></div>
            <div className="flex justify-between items-center mb-4">
              <div className="flex flex-col gap-1">
                <p className="fs-11 text-300">Lucky number</p>
                <span className="bg-white text-gold-900 fs-12 font-semibold rounded-full w-[25px] h-[25px] text-center p-1">11</span>
              </div>
              <button className="bg-white text-500 font-semibold rounded-full px-4 py-2 fs-12">
                Claim Prize!
              </button>
            </div>
            <div className="flex justify-between items-center bg-white p-2 rounded-md">
              <p className="fs-12 text-300">My ticket numbers</p>
              <div className="flex">
                {[11, 13, 14, 21, 22].map(number => (
                  <span key={number} className="bg-white text-yellow-500 border-[1px] border-yellow-500 fs-12 font-semibold rounded-full w-[25px] h-[25px] text-center p-1 mr-1">{number}</span>
                ))}
              </div>
            </div>

          </div> */}

          {/* Winner Component */}
          <div className="mb-4 text-400">
            <h2 className="fs-14 font-bold mb-4">{getLanguageFile.winner}</h2>
            <div className="flex flex-col gap-2 justify-center mb-4 bg-foundation-grey-light-hover rounded-lg px-4 py-2">
              <div className="flex items-center">
                <div className="flex items-center">
                  <img src="https://via.placeholder.com/40" alt="Winner" className="w-[24px] h-[24px] rounded-full mr-2" />
                  <h3 className="fs-12 font-medium">Kyaw Kyaw</h3>
                </div>
                <p className="fs-12 ml-auto">30 Jan 2024</p>

              </div>
              <p className="fs-12">Botataung Township</p>

            </div>

          </div>

          <button
            className="bg-gold-500 text-500 font-semibold rounded-full px-4 py-3 w-full max-w-md mb-4 fs-14"
            onClick={() => navigate('/lucky-draw')}
          >
            {getLanguageFile.buy_more_ticket_or_claim_prize}
          </button>
        </div>
      </div>

      <BottomNav
        active={3}
        getLanguageFile={getLanguageFile}
      />

      {/* Modals */}
      <YouWinModal show={showWinModal} onClose={() => setShowWinModal(false)} luckyNumber={11} />
      <TryAgainModal show={showTryAgainModal} onClose={() => setShowTryAgainModal(false)} luckyNumber={7} />
    </>
  );
}
