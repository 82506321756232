import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AuthHeader from "../../layout/AuthHeader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { EyeIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
SwiperCore.use([Navigation]);

export default function Promotion() {
  const navigate = useNavigate();

  const promotion = useSelector(
    (state) => state?.others?.promotion?.data?.data
  );
  const AccessToken = useSelector((state) => state.AccessToken);
  const setting = useSelector((state) => state?.others?.setting?.data?.data);

  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = useRef(null);

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  useEffect(() => {
    callApi("others/promotion")
      .withHeaders({ "X-localization": window.localStorage.currentLanguage })
      .executeDispatch();
  }, []);

  return (
    <>
      <header
        id="Top_Navbar"
        className="flex p-tb-9 p-lr-16 flex-col self-stretch shadow-md"
      >
        <nav className="flex justify-between items-center">
          {setting?.site_logo ? (
            <img
              src={setting?.site_logo}
              alt=""
              className="w-[auto] h-[20px] ms-1"
            />
          ) : (
            <span className="text-red-500 fs-16 font-semibold lh-18 text-nowrap">
              {setting?.site_name}
            </span>
          )}
          {/* <div className="fs-16 text-500 font-bold text-center leading-[24px] whitespace-nowrap">Lucky Mall</div> */}
          <div className="">
            {/* <button className="text-blue-500 fs-14 font-bold" onClick={() => navigate('/')} >
              {getLanguageFile.skip}
            </button> */}
          </div>
        </nav>
      </header>
      <div
        id="body"
        className="auth bottombutton"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        {promotion?.image && (
          <Swiper
            id={"promotion"}
            ref={swiperRef}
            modules={[Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            className="overflow-hidden shadow-lg"
            onInit={(swiper) => {
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            onSlideChange={(swiper) => {
              setCurrentSlide(swiper.activeIndex);
            }}
          >
            <section className="m-t-32 m-b-8">
              {/* <h1 className="fs-24 w-[340px] mx-auto font-semibold leading-[30px] text-500 text-center">
                    {promotion?.title}
                  </h1> */}
            </section>

            <section className="container p-lr-16 m-b-24">
              <div className="bg-gold-50 w-full flex justify-center p-tb-24 rounded-md">
                <img
                  className="w-[231px]"
                  src={promotion?.image}
                  alt="Slide 1"
                />
              </div>
            </section>

            <section className="container p-lr-16 m-b-24">
              <div className="flex justify-center">
                <span className="fs-12 font-normal text-300">
                  <div
                    dangerouslySetInnerHTML={{ __html: promotion?.content }}
                  />
                </span>
              </div>
            </section>
          </Swiper>
        )}
      </div>

      <div className="w-100 p-lr-16 max-w-[393px] sm:max-w-[none] bottom-0 bg-white shadow-md-top">
        <div className="grid grid-cols-1 gap-2">
          <button
            onClick={() => {
              if (AccessToken) {
                navigate("/");
              } else {
                navigate("/signup");
              }
            }}
            className="flex items-center justify-center m-tb-16 bg-gold-500 text-500 font-semibold 
          fs-14 rounded-full p-lr-32 p-tb-12 w-100"
          >
            {getLanguageFile.sign_up}
          </button>
        </div>
      </div>
    </>
  );
}
