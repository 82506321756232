import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import {
  GiftIcon,
  HomeIcon,
  ShoppingCartIcon,
  StarIcon,
  TicketIcon,
} from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import BottomNav from "../../layout/BottomNav";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import Header from "../../layout/Header";
import Marquee from "react-fast-marquee";
import PWAInstalllationAlert from "./PWAInstalllationAlert";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";

export default function HomePage() {
  const navigate = useNavigate();

  const sliders = useSelector((state) => state?.home?.sliders?.data?.data);
  const homeproduct = useSelector(
    (state) => state?.home?.homeproduct?.data?.data
  );
  const homelatest = useSelector(
    (state) => state?.home?.homelatest?.data?.data
  );
  const profile = useSelector((state) => state?.profile?.profile?.data?.data);
  const winnerSlide = useSelector(
    (state) => state?.others?.winnerSlide?.data?.data
  );
  const AccessToken = useSelector((state) => state.AccessToken);
  // Change language
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
    // localStorage.setItem("currentLanguage", languageType);
  };
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  function handleBuyTokenClick(item) {
    navigate("/lucky-draw-all-prize", { state: { buytokenid: item?.id } });
  }

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
          localStorage.setItem("pwaInstalled", "true"); // Mark PWA as installed
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
        setShowInstallPrompt(false); // Hide the prompt
      });
    }
  };

  useEffect(() => {
    callApi("home/sliders").executeDispatch();
    callApi("home/homeproduct")
      .withHeaders({ "X-localization": window.localStorage.currentLanguage })
      .executeDispatch();
    callApi("home/homelatest")
      .withHeaders({ "X-localization": window.localStorage.currentLanguage })
      .executeDispatch();
    callApi("others/winnerSlide").executeDispatch();

    // Check if app is running as a PWA
    const isAppInstalled =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone;

    if (!isAppInstalled && !localStorage.getItem("pwaInstalled")) {
      // Listen for beforeinstallprompt event
      const handleBeforeInstallPrompt = (e) => {
        e.preventDefault();
        // Save the event for later use
        setDeferredPrompt(e);
        // Show the custom install prompt
        setShowInstallPrompt(true);
      };

      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    }
  }, []);

  useEffect(() => {
    if (AccessToken) {
      callApi("profile/profile").executeDispatch();
    }
  }, [AccessToken]);

  // useEffect(() => {
  //   //
  //   // localStorage.setItem("currentLanguage", changeLanguage);
  // }, [changeLanguage]);

  return (
    <>
      {showInstallPrompt && (
        <PWAInstalllationAlert
          type={"message"}
          text={"Would you like to install this app on your home screen?"}
          btnLabel={"Install"}
          onClick={() => {
            setShowInstallPrompt(false)
            handleInstallClick()
          }}
          cancel={getLanguageFile.cancel}
          close={() => setShowInstallPrompt(false)}
        />
      )}

      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div id="body" className="bottombar" style={{ overflow: "hidden auto" }}>
        <section className="m-b-16">
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            className="overflow-hidden shadow-lg"
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {sliders?.map((item, key) => (
              <SwiperSlide key={key}>
                <a href={item?.url} target="_blank">
                  <img src={item?.image} alt={item?.name} className="w-full" />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>

        <section className="container p-lr-16 m-b-24">
          <div
            onClick={() => navigate("/prize-winners")}
            className="flex items-center justify-between bg-red-500 text-white font-semibold rounded-lg px-4 py-2 cursor-pointer"
          >
            <Marquee>
              {winnerSlide?.map((eachWinnerSlide, key) => (
                <div key={key}>
                  <span className="text-white fs-12 font-medium pe-2">
                    {eachWinnerSlide?.product} Winner
                  </span>
                  <span className="text-white fs-12 font-bold pe-[90px]">
                    {eachWinnerSlide?.name}
                  </span>
                </div>
              ))}
            </Marquee>

            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
        </section>

        <section className="container p-lr-16 m-b-16">
          <div className="flex items-center justify-between">
            <span className="flex items-center fs-16">
              {getLanguageFile.homePageLuckyDraw}
            </span>

            <button
              onClick={() => navigate("lucky-draw-all-prize")}
              className="flex items-center fs-12"
            >
              {getLanguageFile.view_all}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-[16px] h-[16px]"
              >
                <path
                  fillRule="evenodd"
                  d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </section>

        <section className="container p-lr-16 m-b-24">
          <Swiper
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={1.1}
            className="overflow-hidden"
          >
            {homeproduct?.map((item, key) => (
              <SwiperSlide key={key}>
                <div className="flex flex-col flex-wrap p-lr-16 p-tb-14 bg-gray-200 border-[1px] border-gray-300 rounded-lg">
                  <button
                    onClick={() => handleBuyTokenClick(item)}
                    // onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
                    className="flex text-start"
                  >
                    <div className="w-1/3">
                      <span>
                        <img
                          src={
                            item?.feature_image
                              ? item?.feature_image
                              : LuckydrawItem1
                          }
                          alt="Slide 1"
                          className="w-full rounded-md"
                        />
                      </span>
                    </div>
                    <div className="w-2/3 p-lr-16 p-tb-8 flex flex-col">
                      <span className="fs-14 font-semibold">{item?.name}</span>
                      <span className="fs-11">{item?.category}</span>
                      <span className="fs-11 font-semibold">
                        MMK {item?.actual_price}
                      </span>
                      {/* {item?.off_price > 0 && (
                        <span className="fs-10 line-through text-300 leading-[16px] me-1">
                          MMK {item?.off_price}
                        </span>
                      )} */}
                      {/* <a href={"/"} className="fs-11 text-red-500 underline">
                        View Live
                      </a> */}
                    </div>
                  </button>

                  <div className="border-t border-gray-300 my-4"></div>

                  <div className="flex justify-between items-center">
                    <div className="w-1/2">
                      <span className="text-gray-600 fs-11">
                        remaining tickets :{" "}
                        <span className="text-customGray">
                          {item?.remaining_tickets}
                        </span>{" "}
                        / {item?.ticket}
                      </span>
                      <div className="w-full bg-gray-300 h-3 rounded-full">
                        <div
                          className="bg-yellow-400 h-3 rounded-full"
                          style={{
                            width: `${
                              (item?.remaining_tickets / item?.ticket) * 100
                            }%`,
                          }}
                        ></div>
                      </div>
                    </div>

                    <button
                      onClick={() =>
                        AccessToken
                          ? handleBuyTokenClick(item)
                          : navigate("/signin")
                      }
                      className="w-[128px] flex items-center p-lr-16 p-tb-6 bg-yellow-400 rounded-full"
                    >
                      <span className="flex p-all-4 gap-[10px] rounded-full bg-white m-r-10">
                        <TicketIcon
                          id="StarIcon"
                          className="flex w-[12px] h-[12px] justify-center items-center shrink-0 text-yellow-400"
                        />
                      </span>
                      <span className="fs-12 font-semibold">
                        {item?.cost_token_point}{" "}
                        {item?.cost_token_point > 1
                          ? getLanguageFile.tokens
                          : getLanguageFile.token}
                      </span>
                    </button>
                  </div>

                  <div className="absolute right-2 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                    <span className="text-white fs-8 font-medium">
                      {item?.label}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>

        <section className="container p-lr-16 m-b-16">
          <h1 className="fs-16 font-bold m-b-16">
            {getLanguageFile.browse_best_deal}
          </h1>

          <div className="grid grid-cols-2 gap-2">
            {homelatest?.map((item, key) => (
              <button
                onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
                key={key}
                className="flex flex-col relative bg-foundation-grey-light-hover rounded-b-md text-start"
              >
                {/* <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                  <span className="text-white fs-8 font-medium">60% off</span>
                </div> */}

                <img
                  src={
                    item?.feature_image ? item?.feature_image : LuckydrawItem1
                  }
                  alt="Slide 1"
                  className="w-full rounded-t-md"
                />

                <div className="p-lr-8 p-tb-16">
                  <div className="fs-11 font-medium overflow-hidden whitespace-nowrap text-ellipsis">
                    {item?.name}
                  </div>
                  <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                    {item?.category}
                  </div>

                  <span className="flex items-center leading-normal">
                    <span className="fs-11 font-bold me-1">MMK</span>
                    <span className="fs-16 font-extrabold leading-[16px]">
                      {item?.actual_price}
                    </span>
                  </span>
                </div>
              </button>
            ))}
          </div>
        </section>

        <div className="m-b-10"></div>
      </div>

      <BottomNav active={1} getLanguageFile={getLanguageFile} />
    </>
  );
}
