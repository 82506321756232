import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import {
  ArrowLeftIcon,
  StarIcon,
  PencilSquareIcon,
  PlusIcon,
  MinusIcon,
  HomeIcon,
  ShoppingCartIcon,
  CubeIcon,
  TruckIcon,
  GiftIcon,
} from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import BottomNav from "../../layout/BottomNav";
import AuthHeader from "../../layout/AuthHeader";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import ReviewModal from "../product/ReviewModal";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { ClipLoader } from "react-spinners";

export default function MyOrdersShippingDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const [alertmessage, setAlertmessage] = useState("");
  const [reviewAlertmessage, setReviewAlertmessage] = useState("");
  const [serverError, setServerError] = useState(null);
  const [reviewModalToggle, setReviewModalToggle] = useState(false);

  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const shippingOrdersDetail = useSelector(
    (state) => state?.history?.shippingOrdersDetail?.data?.data
  );

  useEffect(() => {
    callApi("history/shippingOrdersDetail")
      .withKeyParameter({ id: id })
      .loadingGroup("MyOrdersShippingDetail")
      .executeDispatch();
  }, []);

  function handleReceived() {
    callApi("history/shippingOrderReceive")
      .withBody({ shipping_order_id: id })
      .execute()
      .then((res) => {
        if (res?.status_code == 200) {
          setAlertmessage(res?.message || "Success");
        }
      });
  }

  return (
    <>
      {alertmessage && (
        <AlertPopup
          type={"success"}
          btnLabel={"Ok"}
          text={alertmessage}
          onClick={() => {
            setAlertmessage(null);
            setReviewModalToggle(true);
          }}
        />
      )}
      {reviewAlertmessage && (
        <AlertPopup
          type={"success"}
          btnLabel={"Ok"}
          text={reviewAlertmessage}
          onClick={() => {
            setReviewAlertmessage(null);
          }}
        />
      )}
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={() => setServerError(null)}
        />
      )}

      <AuthHeader title={getLanguageFile.my_orders} backkey={true} />

      <div
        id="body"
        className={
          shippingOrdersDetail?.status == "received"
            ? "auth"
            : "auth bottombutton"
        }
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <LoadingComponent
          loadingGroup={"MyOrdersShippingDetail"}
          loadingDesign={
            <div className="flex justify-center content-center w-100 mt-5">
              <ClipLoader color="#fed801" size={20} />
            </div>
          }
        >
          <section className="container p-lr-16 m-b-16">
            <div className="flex m-b-24">
              <div className="flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] border-foundation-grey-light-hover">
                <button
                  onClick={() => navigate("/my-orders-to-ship")}
                  className="text-500 fs-12 font-medium p-all-8"
                >
                  {getLanguageFile.to_ship}
                </button>
              </div>

              <div
                className={`flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] ${
                  shippingOrdersDetail?.status == "received"
                    ? `border-foundation-grey-light-hover`
                    : `border-foundation-grey-normal-hover`
                }`}
              >
                <button
                  onClick={() => navigate("/my-orders-shipping")}
                  className="text-500 fs-12 font-medium p-all-8"
                >
                  {getLanguageFile.shipping}
                </button>
              </div>

              <div
                className={`flex items-center justify-between bg-white text-500 font-semibold rounded-full m-l-4 border-[1px] ${
                  shippingOrdersDetail?.status == "received"
                    ? `border-foundation-grey-normal-hover`
                    : `border-foundation-grey-light-hover`
                }`}
              >
                <button
                  onClick={() => navigate("/my-orders-received")}
                  className="text-500 fs-12 font-medium p-all-8"
                >
                  {getLanguageFile.received}
                </button>
              </div>
            </div>
          </section>

          <section className="container p-lr-16 m-b-16">
            <div className="flex flex-col">
              <span className="flex items-center leading-normal mb-1">
                {/* <span className="fs-11 font-bold me-1">MMK</span> */}
                <span className="fs-16 font-extrabold leading-[16px]">
                  Shipping ID: {shippingOrdersDetail?.shipping_order_id}
                </span>
              </span>

              {shippingOrdersDetail?.status && (
                <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1">
                  Status:{" "}
                  {shippingOrdersDetail?.status == "approved" ? (
                    <span
                      style={{ color: "green" }}
                      className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1"
                    >
                      On the way
                    </span>
                  ) : shippingOrdersDetail?.status == "pending" ? (
                    <span
                      style={{ color: "orange" }}
                      className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1"
                    >
                      Processing
                    </span>
                  ) : shippingOrdersDetail?.status == "received" ? (
                    <span
                      style={{ color: "DarkCyan" }}
                      className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1"
                    >
                      Received
                    </span>
                  ) : (
                    shippingOrdersDetail?.status
                  )}
                </div>
              )}

              {shippingOrdersDetail?.shipping_order_items && (
                <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1">
                  Items: {shippingOrdersDetail?.shipping_order_items?.length}
                </div>
              )}

              <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                Total Price: {shippingOrdersDetail?.shipping_price}
              </div>

              <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                Payment: {shippingOrdersDetail?.payment}
              </div>

              <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                Order Date: {shippingOrdersDetail?.order_date}
              </div>
            </div>
          </section>

          <section className="container p-lr-16 m-b-16">
            <div className="flex flex-col justify-center">
              <div className="w-full flex items-center justify-center space-x-4">
                {/* Step 1 */}
                <div className="flex flex-col items-center">
                  <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gold-500 mb-1">
                    <CubeIcon className="w-6 h-6 text-500" />
                    {/* Adjust the size and color as needed */}
                  </div>
                  <span className="text-500 fs-12 font-bold">To Ship</span>
                </div>

                {/* Progress Line 1 */}
                <div className="w-16 h-1 bg-gold-500 mb-[15px]"></div>

                {/* Step 2 */}
                <div className="flex flex-col items-center">
                  <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gold-500 mb-1">
                    <TruckIcon className="w-6 h-6 text-500" />
                  </div>
                  <span className="text-500 fs-12 font-bold">Shipping</span>
                </div>

                {/* Progress Line 2 */}
                <div
                  className={`w-16 h-1 ${
                    shippingOrdersDetail?.status == "received"
                      ? `bg-gold-500`
                      : `bg-gray-300`
                  }  mb-[15px]`}
                ></div>

                {/* Step 3 */}
                <div className="flex flex-col items-center">
                  <div
                    className={`w-10 h-10 flex items-center justify-center rounded-full ${
                      shippingOrdersDetail?.status == "received"
                        ? `bg-gold-500`
                        : `bg-gray-300`
                    } mb-1`}
                  >
                    <PencilSquareIcon className="w-6 h-6 text-500" />
                  </div>
                  <span className="text-500 fs-12 font-bold">Received</span>
                </div>
              </div>
            </div>
          </section>

          <section className="container p-lr-16 m-b-16">
            <div className="flex flex-col">
              {shippingOrdersDetail?.shipping_order_items?.map((item, key) => (
                <div key={key}>
                  <div className="flex relative">
                    <div href="product-detail" className="w-5/12 relative">
                      <img
                        src={
                          item?.product?.feature_image
                            ? item?.product?.feature_image
                            : LuckydrawItem1
                        }
                        alt="Slide 1"
                        className=""
                      />

                      {/* <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                      <span className="text-white fs-8 font-medium">
                        60% off
                      </span>
                    </div> */}
                    </div>

                    <div className="flex-grow w-7/12 p-lr-8 p-tb-16">
                      <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                        {item?.product?.name}
                      </div>
                      <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                        {item?.product?.category}
                      </div>
                      <span className="flex items-center leading-normal">
                        <span className="fs-11 font-bold me-1">MMK</span>
                        <span className="fs-16 font-extrabold leading-[16px]">
                          {item?.product?.actual_price}
                        </span>
                      </span>

                      {item?.type && (
                        <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1">
                          Type: {item?.type}
                        </div>
                      )}

                      {item?.status && (
                        <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1">
                          Status:{" "}
                          {item?.status == "approved" ? (
                            <span
                              style={{ color: "green" }}
                              className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1"
                            >
                              On the way
                            </span>
                          ) : item?.status == "pending" ? (
                            <span
                              style={{ color: "orange" }}
                              className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1"
                            >
                              Processing
                            </span>
                          ) : item?.status == "received" ? (
                            <span
                              style={{ color: "DarkCyan" }}
                              className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis mb-1"
                            >
                              Received
                            </span>
                          ) : (
                            item?.status
                          )}
                        </div>
                      )}

                      <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                        Weight: {item?.product?.kg}
                      </div>
                      {/* <div className="m-b-16">
                      <span className="fs-11 text-300 leading-normal me-1">
                        {getLanguageFile.shipping}
                      </span>

                      <span className="fs-11 text-red-500 leading-[16px]">
                        MMK 5,000
                      </span>
                    </div> */}

                      {/* <div className="flex m-b-8">
                      <div className="flex items-center justify-between bg-foundation-grey-light-hover text-500 font-semibold rounded-full h-[24px] p-lr-8 p-tb-4 m-l-4 border-[2px] border-foundation-grey-light-hover">
                        <span className="text-500 fs-12 font-semibold">
                          {getLanguageFile.arrive_in} 2 to 3 days
                        </span>
                      </div>
                    </div> */}
                    </div>
                  </div>

                  <div className="border-t-2 border-Light/active mb-4"></div>
                </div>
              ))}
            </div>
          </section>
        </LoadingComponent>
      </div>

      {shippingOrdersDetail?.status != "received" && (
        <div className="w-100 p-lr-16 max-w-[393px] sm:max-w-[none] bottom-0 bg-white shadow-md-top">
          <div className="grid grid-cols-1 gap-2">
            <LoadingComponent
              loadingGroup={"MyOrdersShippingDetail"}
              loadingDesign={
                <button
                  disabled={true}
                  onClick={() => handleReceived()}
                  className="flex items-center justify-center m-tb-16 bg-gold-500 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100"
                >
                  {getLanguageFile.received}
                </button>
              }
            >
              <button
                disabled={
                  shippingOrdersDetail?.status == "approved" ? false : true
                }
                onClick={() => handleReceived()}
                className="flex items-center justify-center m-tb-16 bg-gold-500 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100"
              >
                {getLanguageFile.received}
              </button>
            </LoadingComponent>
          </div>
        </div>
      )}

      <ReviewModal
        visible={reviewModalToggle}
        onClose={() => setReviewModalToggle(false)}
        setAlertmessage={setReviewAlertmessage}
        setServerError={setServerError}
      />
    </>
  );
}
