import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Banner1 from "../../assets/images/banner1.png";
import LuckydrawItem1 from "../../assets/images/LuckydrawItem1.jpg";
import { StarIcon } from "@heroicons/react/24/solid";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { ClipLoader } from "react-spinners";

export default function Category() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const category_id = params.get("category_id");
  const categories = useSelector(
    (state) => state?.home?.categories?.data?.data
  );
  const productsByCategory = useSelector(
    (state) => state?.products?.productsByCategory?.data?.data
  );
  const AccessToken = useSelector((state) => state.AccessToken);

  // Change language
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
    if (category_id) {
      callApi("products/productsByCategory")
        .withHeaders({ "X-localization": window.localStorage.currentLanguage })
        .withKeyParameter({ categoryid: category_id })
        .loadingGroup("productsByCategory")
        .executeDispatch();
    }
  }, [category_id]);

  useEffect(() => {
    if (AccessToken) {
      // callApi("profile/profile")
      //   .executeDispatch()
    }
  }, [AccessToken]);

  return (
    <>
      <Header
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
      />

      <div id="body" style={{ overflow: "hidden auto", marginTop: "10px" }}>
        <LoadingComponent
          loadingGroup={"productsByCategory"}
          loadingDesign={
            <div className="flex justify-center content-center w-100 mt-5">
              <ClipLoader color="#fed801" size={20} />
            </div>
          }
        >
          <section className="container p-lr-16 m-b-24">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex space-x-2 fs-12">
                <li>
                  <a href={"/"} className="text-gray-500 hover:text-red-500">
                    {getLanguageFile.home}
                  </a>
                </li>
                <li>
                  <span className="text-gray-500"> \ </span>
                </li>
                <li>
                  <a href={"/"} className="text-gray-500 hover:text-red-500">
                    {
                      categories?.find(
                        (category) => category?.id == category_id
                      )?.name
                    }
                  </a>
                </li>
              </ol>
            </nav>
          </section>

          <section className="container p-lr-16 m-b-16">
            <h1 className="fs-16 font-bold m-b-16">
              {
                categories?.find((category) => category?.id == category_id)
                  ?.name
              }
            </h1>

            <div className="grid grid-cols-2 gap-2">
              {productsByCategory?.map((item, key) => (
                <button
                  key={key}
                  onClick={() => navigate(`/product-detail?id=${item?.slug}`)}
                  className="flex flex-col relative bg-foundation-grey-light-hover rounded-b-md"
                >
                  <img
                    src={item?.feature_image}
                    alt="Slide 1"
                    className="w-full rounded-t-md"
                  />

                  <div className="flex justify-between items-center p-lr-8 p-tb-16">
                    <span className="fs-12 text-500 font-extrabold leading-[16px]">
                      {item?.name}
                    </span>
                    <ChevronRightIcon className="flex w-[14px] h-[14px] font-extrabold justify-center items-center shrink-0 text-500" />
                  </div>
                </button>
              ))}
            </div>
          </section>

          <section className="container p-lr-16 m-b-16"></section>
        </LoadingComponent>
      </div>
    </>
  );
}
