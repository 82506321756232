import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const TryAgainModal = ({ show, onClose, luckyNumber }) => {
  const navigate = useNavigate();

  if (!show) return null;
  // Change language
  const changeLanguage= (window.localStorage.currentLanguage == 'en') ? 'en' : 'mm';
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[300px] p-6 rounded-lg relative">
        <button onClick={onClose} className="absolute top-2 right-2">
          <XMarkIcon className="w-5 h-5 text-gray-500" />
        </button>
        <div className="flex flex-col items-center mb-4">
          <h2 className="fs-20 font-semibold mb-2 text-center">{getLanguageFile.sorry}!</h2>
          <h3 className="fs-24 font-bold mb-2 text-center" style={{ lineHeight: '24px' }}>{getLanguageFile.wish_good_luck_next_time}</h3>
          <p className="fs-14 font-semibold text-center">Lucky number is</p>
          <div className="flex items-center justify-center mt-4 mb-4">
            <div className="bg-gold-500 text-500 font-semibold rounded-full h-[50px] flex items-center justify-center px-2">
              {luckyNumber}
            </div>
          </div>
        </div>
        <button
          onClick={onClose}
          className="bg-white border-[1px] fs-14 border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 w-full mb-1"
        >
          {getLanguageFile.try_other_items}
        </button>
        <button
          onClick={() => navigate('/')}
          className="fs-14 border-foundation-grey-normal-hover text-500 font-semibold rounded-full px-4 py-2 w-full mb-2"
        >
          {getLanguageFile.go_home_page}
        </button>
      </div>
    </div>
  );
};

export default TryAgainModal;
