import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthHeader from '../../layout/AuthHeader';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import * as Yup from "yup";
import { useFormik } from 'formik';
import AlertPopup from '../../components/AlertPopUp/AlertPopUp';
import callApi from '../../services/api/apiClient';

const ResetPasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  new_password: Yup.string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords do not match')
    .required("New password confirmation is required"),
});

export default function ResetPassword() {
  const [changeLanguage, setChangeLanguage] = useState(window.localStorage.currentLanguage || 'en');
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const navigate = useNavigate();
  const [serverError, setServerError] = useState(null);
  const [serverSuccess, setServerSuccess] = useState(null);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, actions) => {
      callApi("auth/resetpassword")
        .withBody({
          old_password: values.old_password,
          new_password: values.new_password,
          new_password_confirmation: values.new_password_confirmation,
        })
        .execute()
        .then((res) => {
          if (res?.status_code == 200) {
            actions.resetForm();
            setServerSuccess(res?.message)
          } else {
            setServerError(res?.message);
          }
        })
        .catch((err) => console.log("error", err));
      actions.setSubmitting(false);
    },
  });

  const handleClose = () => {
    setServerError(null);
    setServerSuccess(null);
  };

  return (
    <>
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={handleClose}
        />
      )}
      {serverSuccess && (
        <AlertPopup
          type={"success"}
          btnLabel={"Ok"}
          text={serverSuccess}
          onClick={handleClose}
        />
      )}

      <AuthHeader title={getLanguageFile.reset_password} backkey={true} />

      <div id="body" className="auth" style={{ overflow: 'hidden auto', marginTop: '10px' }}>
        <form onSubmit={formik.handleSubmit}>
          <section className="container p-lr-16 m-b-8">
            <div className="flex flex-col mb-4">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.old_password} <span className="text-red-500">*</span>
                {formik.errors.old_password && formik.touched.old_password && (
                  <span className="text-red-500">{formik.errors.old_password}</span>
                )}
              </label>
              <span className="flex justify-between items-center text-gray-500 p-lr-8 w-100 border-[1px] border-Light/active rounded-md focus-within:border-blue-500">
                <input
                  type={showOldPassword ? "text" : "password"}
                  name="old_password"
                  placeholder={getLanguageFile.type_old_password}
                  className="w-100 p-tb-15 password-input outline-none rounded-xl"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.old_password}
                />
                <button type="button" onClick={() => setShowOldPassword(!showOldPassword)}>
                  {showOldPassword ? (
                    <EyeIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  ) : (
                    <EyeSlashIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  )}
                </button>
              </span>
            </div>

            <div className="flex flex-col mb-4">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.new_password} <span className="text-red-500">*</span>
                {formik.errors.new_password && formik.touched.new_password && (
                  <span className="text-red-500">{formik.errors.new_password}</span>
                )}
              </label>
              <span className="flex justify-between items-center text-gray-500 p-lr-8 w-100 border-[1px] border-Light/active rounded-md focus-within:border-blue-500">
                <input
                  type={showNewPassword ? "text" : "password"}
                  name="new_password"
                  placeholder={getLanguageFile.type_new_password}
                  className="w-100 p-tb-15 password-input outline-none rounded-xl"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.new_password}
                />
                <button type="button" onClick={() => setShowNewPassword(!showNewPassword)}>
                  {showNewPassword ? (
                    <EyeIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  ) : (
                    <EyeSlashIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  )}
                </button>
              </span>
            </div>

            <div className="flex flex-col mb-4">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.confirm_new_password} <span className="text-red-500">*</span>
                {formik.errors.new_password_confirmation && formik.touched.new_password_confirmation && (
                  <span className="text-red-500">{formik.errors.new_password_confirmation}</span>
                )}
              </label>
              <span className="flex justify-between items-center text-gray-500 p-lr-8 w-100 border-[1px] border-Light/active rounded-md focus-within:border-blue-500">
                <input
                  type={showNewPasswordConfirmation ? "text" : "password"}
                  name="new_password_confirmation"
                  placeholder={getLanguageFile.type_confirm_new_password}
                  className="w-100 p-tb-15 password-input outline-none rounded-xl"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.new_password_confirmation}
                />
                <button type="button" onClick={() => setShowNewPasswordConfirmation(!showNewPasswordConfirmation)}>
                  {showNewPasswordConfirmation ? (
                    <EyeIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  ) : (
                    <EyeSlashIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  )}
                </button>
              </span>
            </div>

            <button
              type="submit"
              disabled={Object.keys(formik.errors).length > 0}
              className="flex items-center justify-center bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-16 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50"
            >
              {getLanguageFile.reset_password}
            </button>
          </section>
        </form>
      </div>
    </>
  );
}
