export const toastNotification = ({ title, body }) => {
  console.log(`Toast Notification: ${title} - ${body}`);
  const notification = new Notification(title, {
    body: body,
  });
  notification.onclick = function (event) {
    event.preventDefault();
    // window.open(url,'_blank')
  }
};

export const sendNativeNotification = ({ title, body }) => {
  if ("Notification" in window) {
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        const options = {
          body: body,
        };
        new Notification(title, options);
      } else {
        alert("We cannot send you any notification because notification permission is not granted.");
      }
    });
  } else {
    alert("This browser does not support notifications.");
  }
};