import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StarIcon, UserCircleIcon, GiftIcon } from '@heroicons/react/24/solid'
import { HomeIcon } from '@heroicons/react/16/solid'
import { HomeIcon as HomeIconOutline, GiftIcon as GiftIconOutline, UserCircleIcon as UserCircleIconOutline } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux';

export default function BottomNav({ active, getLanguageFile = "" }) {
  const navigate = useNavigate()
  const AccessToken = useSelector(state => state.AccessToken);
  return (
    <div className="w-100 p-lr-16 max-w-[393px] sm:max-w-[none] bottom-0 bg-white shadow-md-top">
      <div className="flex justify-around py-2">
        <button onClick={() => navigate('/')} className={`flex flex-col items-center ${active == 1 ? 'text-red-500' : 'text-300'}`}>
          {
            active == 1 ?
              <HomeIcon className="flex w-[24px] h-[24px] m-all-5 justify-center items-center shrink-0 font-bold" />
              :
              <HomeIconOutline className="flex w-[24px] h-[24px] m-all-5 justify-center items-center shrink-0 font-bold" />

          }
          <span className="text-xs">{getLanguageFile.nav_home}</span>
        </button>
        <button onClick={() => navigate('/token')} className={`flex flex-col items-center ${active == 2 ? 'text-red-500 border-red-500' : 'text-300 border-300'}`}>
          {
            active == 2
              ?
              <div className={`border-solid border-[1px] m-all-5 rounded-full border-red-500 bg-red-500`}>
                <StarIcon className="flex w-[16px] h-[16px] text-white m-all-3 justify-center items-center shrink-0 font-bold" />
              </div>
              :
              <div className={`border-solid border-[1px] m-all-5 rounded-full border-300`}>
                <StarIcon className="flex w-[16px] h-[16px] m-all-3 justify-center items-center shrink-0 font-bold" />
              </div>
          }
          <span className="text-xs">{getLanguageFile.nav_cart}</span>

        </button>
        <button onClick={() => navigate('/lucky-draw', { state: { data: "history" } })}  className={`flex flex-col items-center ${active == 3 ? 'text-red-500' : 'text-300'}`}>
          {
            active == 3 ?
              <GiftIcon className="flex w-[24px] h-[24px] m-all-5 justify-center items-center shrink-0 font-bold" />
              :
              <GiftIconOutline className="flex w-[24px] h-[24px] m-all-5 justify-center items-center shrink-0 font-bold" />
          }
          <span className="text-xs">{getLanguageFile.nav_lucky}</span>
        </button>
        <button onClick={() => AccessToken ? navigate('/profile') : navigate('/signin')} className={`flex flex-col items-center ${active == 4 ? 'text-red-500' : 'text-300'}`}>
          {
            active == 4 ?
              <UserCircleIcon className="flex w-[24px] h-[24px] m-all-5 justify-center items-center shrink-0 font-bold" />
              :
              <UserCircleIconOutline className="flex w-[24px] h-[24px] m-all-5 justify-center items-center shrink-0 font-bold" />
          }
          <span className="text-xs">{getLanguageFile.nav_profile}</span>
        </button>
      </div>
    </div>
  )
}