// firebase.js
import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { dispatchStore } from '../lib/dispatchStore';
import { FirebaseTokenSlice } from '../redux/reducers/reducer';

const firebaseConfig = {
  apiKey: "AIzaSyCY6AIaSYl97rdMFUN2crtB3CmHldEa1tM",
  authDomain: "lucky-mall-2f3de.firebaseapp.com",
  projectId: "lucky-mall-2f3de",
  storageBucket: "lucky-mall-2f3de.appspot.com",
  messagingSenderId: "661967908679",
  appId: "1:661967908679:web:e7926ddd9877fe40452662",
  measurementId: "G-7DJ64G77KM",
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

getToken(messaging, { vapidKey: "BEvLcqoXvcaUpI4KWr4E6uw8MLxRM2coME8YklXtZWvTkdfW4-no9v9EYjjWziUuLfI-tmmCl56VlSlHf_ZYwpE" }).then((currentToken) => {
  if (currentToken) {
    console.log("token setted")
    dispatchStore(
      FirebaseTokenSlice.actions.setFirebaseToken(currentToken)
    );
  } else {
    console.log('No registration token available. Request permission to generate one.');
  }

}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});

const setupNotifications = async (onMessageCallback) => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get the FCM token
      const token = await getToken(messaging);
    } else {
      console.log('Notification permission denied.');
    }

    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log('Foreground Message:', payload);
      // Pass the payload to the callback
      if (onMessageCallback) {
        onMessageCallback(payload);
      }
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

export { messaging, setupNotifications };