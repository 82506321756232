import React, { useState, useEffect } from "react";
import AuthHeader from "../../layout/AuthHeader";
import BottomNav from "../../layout/BottomNav";
import productImage from "../../assets/images/LuckydrawItem1.jpg";
import livebgimg1 from "../../assets/images/livebgimg1.png";
import { useLocation, useNavigate } from "react-router-dom";
import YouWinModal from "./YouWinModal";
import TryAgainModal from "./TryAgainModal";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import { dispatchStore } from "../../lib/dispatchStore";
import { slice } from "../../redux/reducers/reducer";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { ClipLoader } from "react-spinners";

export default function PrizeWinnerDetail() {
  const navigate = useNavigate();

  const AccessToken = useSelector((state) => state.AccessToken);
  const [changeLanguage, setChangeLanguage] = useState(
    localStorage.getItem("currentLanguage") || "en"
  );
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  };
  const [showWinModal, setShowWinModal] = useState(false);

  const [showTryAgainModal, setShowTryAgainModal] = useState(false);
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);

  const luckyDrawDetail = useSelector(
    (state) => state?.others?.winnerDetail?.data?.data
  );

  const location = useLocation();
  const { data } = location.state || {};
  useEffect(() => {
    localStorage.setItem("currentLanguage", changeLanguage);
  }, [changeLanguage]);

  useEffect(() => {
      callApi("others/winnerDetail")
        .withKeyParameter({ luckydrawid: data })
        .loadingGroup("others/winnerDetail")
        .executeDispatch();
  }, [data]);

  return (
    <>
      <AuthHeader title="Lucky Draw Item Detail" backkey={true} />

      <div
        id="body"
        className="auth bottombar"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <LoadingComponent
          loadingGroup={"others/winnerDetail"}
          loadingDesign={
            <div className="flex justify-center content-center w-100 mt-5">
              <ClipLoader color="#fed801" size={20} />
            </div>
          }
        >
          <div className="container p-lr-16">
            {/* Draw Start In */}
            {/* <section className="flex justify-between items-center mb-4">
              <p className="fs-12 text-gray-500">Open Date</p>
              <p className="fs-12 text-500">{luckyDrawDetail?.created_at}</p>
          </section> */}

            {/* Lucky Number Section */}
            <section
              className="flex flex-col h-[200px] justify-center items-center bg-gold-500 rounded-lg mb-4"
              style={{
                backgroundImage: `url(${livebgimg1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <p className="fs-14 text-center font-semibold text-500 mb-1">
                Batch - {luckyDrawDetail?.batch}
              </p>
              <p className="fs-16 text-center font-semibold text-500">
                Lucky number is
              </p>
              <p className="fs-50 text-center font-semibold text-500 leading-[60px]">
                {luckyDrawDetail?.lucky_number || "?"}
              </p>
            </section>

            {luckyDrawDetail?.is_win ? (
              <div className="bg-yellow-400 pt-4 px-4 pb-2 rounded-lg mb-4">
                <div className="flex items-center mb-4">
                  <img
                    src={
                      luckyDrawDetail?.product?.feature_image
                        ? luckyDrawDetail?.product?.feature_image
                        : productImage
                    }
                    alt="Product"
                    className="w-1/3 rounded-md"
                  />
                  <div className="ml-4 relative">
                    <h3 className="fs-14 font-bold">
                      {luckyDrawDetail?.product?.name}
                    </h3>
                    <p className="fs-11">
                      {luckyDrawDetail?.product?.category}
                    </p>
                    <p className="fs-11 font-bold">
                      MMK {luckyDrawDetail?.product?.actual_price}
                    </p>
                    <p className="fs-11">
                      {getLanguageFile.open_at} {luckyDrawDetail?.opened_at}
                    </p>
                    <div className="absolute top-0 right-0 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                      <span className="text-white fs-8 font-medium">
                        You Win!
                      </span>
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-300 my-4"></div>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex flex-col gap-1">
                    <p className="fs-11 text-300">Lucky number</p>
                    <span className="bg-white text-gold-900 fs-12 font-semibold rounded-full w-[25px] h-[25px] text-center p-1">
                      {luckyDrawDetail?.lucky_number}
                    </span>
                  </div>
                  <button
                    onClick={() => navigate("/my-orders-to-ship")}
                    className="bg-white text-500 font-semibold rounded-full px-4 py-2 fs-12"
                  >
                    Claim Prize!
                  </button>
                </div>
                <div className="flex justify-between items-center bg-white p-2 rounded-md gap-2">
                  <p className="fs-12 text-300 text-nowrap">
                    {getLanguageFile.my_ticket_number}
                  </p>
                </div>
                <div className="flex flex-wrap mt-3">
                  {luckyDrawDetail?.ticket_numbers
                    ?.split(",")
                    .map((num) => parseInt(num.trim(), 10))
                    ?.map((number) => (
                      <span
                        key={number}
                        className="bg-foundation-grey-normal-hover text-white border-[1px] fs-12 font-semibold rounded-full min-w-[25px] min-h-[25px] text-center p-1 mr-1"
                      >
                        {number}
                      </span>
                    ))}
                </div>
              </div>
            ) : (
              <div className="bg-foundation-grey-light-hover pt-4 px-4 pb-2 rounded-lg mb-4">
                <div className="flex items-center mb-4">
                  <img
                    src={
                      luckyDrawDetail?.product?.feature_image
                        ? luckyDrawDetail?.product?.feature_image
                        : productImage
                    }
                    alt="Product"
                    className="w-1/3 rounded-md"
                  />
                  <div className="ml-4 relative">
                    <h3 className="fs-14 font-bold">
                      {luckyDrawDetail?.product?.name}
                    </h3>
                    <p className="fs-11">
                      {luckyDrawDetail?.product?.category}
                    </p>
                    {/* {luckyDrawDetail?.product?.off_price == 0 && (
                  <p className="fs-11">{getLanguageFile.original_price}</p>
                )} */}
                    <p className="fs-11 font-bold">
                      MMK {luckyDrawDetail?.product?.actual_price}
                    </p>
                    <p className="fs-11">
                    {getLanguageFile.open_at} {luckyDrawDetail?.opened_at}
                    </p>
                  </div>
                </div>
                <div className="border-t border-gray-300 my-4"></div>
                <div className="flex justify-between items-center bg-white p-2 rounded-md gap-2">
                  <p className="fs-12 text-300 text-nowrap">
                    {getLanguageFile.my_ticket_number}
                  </p>
                </div>
                <div className="flex flex-wrap mt-3">
                  {luckyDrawDetail?.ticket_numbers
                    ?.split(",")
                    .map((num) => parseInt(num.trim(), 10))
                    ?.map((number) => (
                      <span
                        key={number}
                        className="bg-foundation-grey-normal-hover text-white border-[1px] fs-12 font-semibold rounded-full min-w-[25px] min-h-[25px] text-center p-1 mr-1"
                      >
                        {number}
                      </span>
                    ))}
                </div>
              </div>
            )}

            {/* Winner Component */}
            {luckyDrawDetail?.winner?.name && (
              <div className="mb-4 text-400">
                <h2 className="fs-14 font-bold mb-4">
                  {getLanguageFile.winner}
                </h2>
                <div className="flex flex-col gap-2 justify-center mb-4 bg-foundation-grey-light-hover rounded-lg px-4 py-2">
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <img
                        src={
                          luckyDrawDetail?.winner?.profile_image
                            ? luckyDrawDetail?.winner?.profile_image
                            : "https://via.placeholder.com/40"
                        }
                        alt="Winner"
                        className="w-[24px] h-[24px] rounded-full mr-2"
                      />
                      <h3 className="fs-12 font-medium">
                        {luckyDrawDetail?.winner?.name}
                      </h3>
                    </div>
                    <p className="fs-12 ml-auto">
                      {luckyDrawDetail?.winner?.date}
                    </p>
                  </div>
                  <p className="fs-12">{luckyDrawDetail?.winner?.township}</p>
                </div>
              </div>
            )}

            <button
              className="bg-gold-500 text-500 font-semibold rounded-full px-4 py-3 w-full max-w-md mb-4 fs-14"
              onClick={() => navigate("/lucky-draw")}
            >
              {getLanguageFile.buy_more_ticket_or_claim_prize}
            </button>
          </div>
        </LoadingComponent>
      </div>

      <BottomNav getLanguageFile={getLanguageFile} />
    </>
  );
}
