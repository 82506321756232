import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AuthHeader from "../../layout/AuthHeader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import * as Yup from "yup";
import { dispatchStore } from "../../lib/dispatchStore";
import { AccessTokenSlice } from "../../redux/reducers/reducer";
import callApi from "../../services/api/apiClient";
import { useFormik } from "formik";
import AlertPopup from "../../components/AlertPopUp/AlertPopUp";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { BeatLoader, ClipLoader } from "react-spinners";
import { selectStore } from "../../lib/selectStore";
import LanguageModal from "../user/LanguageModal";
import mm from "../../assets/images/flags/mm.svg";
import en from "../../assets/images/flags/en.svg";
import ch from "../../assets/images/flags/ch.svg";
import {  ChevronRightIcon } from "@heroicons/react/24/solid";

export default function SignIn() {
  // Change language
  const [changeLanguage, setChangeLanguage] = useState(
    window.localStorage.currentLanguage || "en"
  );
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  const [showBuyTicketModal, setShowBuyTicketModal] = useState(false);

  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
    setShowBuyTicketModal(false);
  };

  const SignInSchema = Yup.object().shape({
    phone: Yup.string().required(getLanguageFile.phone_number_require),
    password: Yup.string()
      .min(6, getLanguageFile.password_much_six_char)
      .required(getLanguageFile.password_require),
  });

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [serverError, setServerError] = useState(null);

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SignInSchema,
    onSubmit: (values, actions) => {
      let device_token = selectStore("FirebaseToken");
      callApi("auth/signin")
        .withBody({
          phone: values.phone,
          password: values.password,
          device_token: device_token,
        })
        .loadingGroup("signin")
        .execute()
        .then((res) => {
          if (res?.token) {
            dispatchStore(
              AccessTokenSlice.actions.setAccessToken("Bearer " + res?.token)
            );
          }
          if (res?.status_code == 200) {
            actions.resetForm();
            navigate("/profile");
          } else {
            setServerError(res?.message);
          }
        })
        .catch((err) => console.log("error", err));
      actions.setSubmitting(false);
    },
  });

  const handleClose = () => {
    setServerError(null);
  };
  const flagCircleLayout = {
    width: "20px",
    height: "20px",
    objectFit: "cover",
    borderRadius: "50%",
  };

  return (
    <>
      {serverError && (
        <AlertPopup
          type={"error"}
          btnLabel={"Ok"}
          text={serverError}
          onClick={handleClose}
        />
      )}

      <AuthHeader title={getLanguageFile.sign_in} backkey={true} />

      <div
        id="body"
        className="auth"
        style={{ overflow: "hidden auto", marginTop: "10px" }}
      >
        <section className="m-tb-32">
          <h1 className="fs-24 font-semibold leading-[30px] text-red-500 text-center">
            Lucky Mall
          </h1>
        </section>

        <section className="container p-lr-16 m-b-8">
          <form action="" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.phone_number}
                <span className="text-red-500">*</span>
                {formik?.errors.phone && (
                  <span className="text-red-500">{formik.errors.phone}</span>
                )}
              </label>

              <input
                id="phone"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                type="text"
                placeholder={getLanguageFile.type_phone_number}
                className="text-gray-500 p-tb-15 p-lr-8 w-100 border-[1px] border-Light/active rounded-md"
              />
            </div>

            <div className="flex flex-col">
              <label className="fs-12 font-semibold leading-[30px] text-500">
                {getLanguageFile.password}
                <span className="text-red-500">*</span>
                {formik?.errors.password && (
                  <span className="text-red-500">{formik.errors.password}</span>
                )}
              </label>

              <span className="flex justify-between items-center text-gray-500  p-lr-8 w-100 border-[1px] border-Light/active rounded-md focus-within:border-blue-500">
                <input
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  placeholder={getLanguageFile.type_password}
                  className="w-100 p-tb-15 password-input outline-none rounded-xl"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  ) : (
                    <EyeSlashIcon className="flex w-[16px] h-[16px] font-extrabold justify-center items-center shrink-0 text-300" />
                  )}
                </button>
              </span>
              <span className="fs-12 font-medium text-blue-500 text-end">
                {getLanguageFile.forget_password}?
              </span>
            </div>

            <LoadingComponent
              loadingGroup={"signin"}
              loadingDesign={
                <button
                  disabled={true}
                  type={"submit"}
                  className="flex items-center justify-center bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-16 disabled:cursor-not-allowed"
                >
                  <BeatLoader color="#ffffff" size={13} />
                </button>
              }
            >
              <button
                disabled={
                  Object.keys(formik.errors).length > 0 ||
                  formik.values.phone === "" ||
                  formik.values.password === ""
                }
                type={"submit"}
                className="flex items-center justify-center bg-gold-500 text-500 font-semibold rounded-full p-lr-32 p-tb-12 w-100 m-tb-16 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50"
              >
                {getLanguageFile.sign_in}
              </button>
            </LoadingComponent>

            <h2 className="flex items-center justify-center m-b-8">
              <span className="fs-12 font-medium text-black text-center pe-1">
                {getLanguageFile.dont_have_account}?
              </span>
              <button
                type="button"
                onClick={() => navigate("/signup")}
                className="text-blue-500 font-medium fs-12"
              >
                {getLanguageFile.sign_up}
              </button>
            </h2>
          </form>

          <button
              onClick={() => setShowBuyTicketModal(true)}
              className="flex justify-between items-center py-2 mb-2 mx-auto"
            >
              <div className="flex items-center">
                <img
                  className="m-l-2 w-[20px] h-[20px] me-3 rounded-full"
                  src={
                    window.localStorage.currentLanguage == "en"
                      ? en
                      : window.localStorage.currentLanguage == "mm"
                      ? mm
                      : ch
                  }
                  style={flagCircleLayout}
                />
                <span className="text-300 fs-14 font-normal">
                  {getLanguageFile.language_name}
                </span>
              </div>
            </button>
        </section>
      </div>

      <LanguageModal
        show={showBuyTicketModal}
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}
        onClose={() => setShowBuyTicketModal(false)}
      />
    </>
  );
}
