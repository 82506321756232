import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import Header from '../../layout/Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import Banner1 from '../../assets/images/banner1.png'
import LuckydrawItem1 from '../../assets/images/LuckydrawItem1.jpg'
import { StarIcon } from '@heroicons/react/24/solid'


export default function CategoryDetail() {
  // Change language
  const [changeLanguage, setChangeLanguage ] = useState(window.localStorage.currentLanguage || 'en');
  const changeLanguageData = (languageType) => {
    setChangeLanguage(languageType);
  }
  const getLanguageFile = require(`../../data/${changeLanguage}-language.json`);
  useEffect(() => {
    localStorage.setItem('currentLanguage', changeLanguage);
  }, [changeLanguage]);

  return (
    <>
      <Header 
        changeLanguageClick={changeLanguageData}
        getLanguageFile={getLanguageFile}   
      />

      <div id="body" style={{ overflow: 'hidden auto', marginTop: '10px' }}>

        <section className="container p-lr-16 m-b-24">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex space-x-2 fs-12">
              <li>
                <a href={'/'} className="text-gray-500 hover:text-red-500">Home</a>
              </li>
              <li>
                <span className="text-gray-500"> \ </span>
              </li>
              <li>
                <a href={'/'} className="text-gray-500 hover:text-red-500">Products</a>
              </li>
              <li>
                <span className="text-gray-500"> \ </span>
              </li>
              <li className="text-gray-500">Current Page</li>
            </ol>
          </nav>
        </section>

        <section className="container p-lr-16 m-b-16">
          <h1 className="fs-16 font-bold m-b-16">Phone & Accessories</h1>

          <div className="grid grid-cols-2 gap-2">

            <div className="flex flex-col relative bg-foundation-grey-light-hover rounded-b-md">
              <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                <span className="text-white fs-8 font-medium">
                  60% off
                </span>
              </div>

              <a href="product-detail">
                <img src={LuckydrawItem1} alt="Slide 1" className="w-full rounded-t-md" />
              </a>

              <div className="p-lr-8 p-tb-16">
                <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                  Household refrigerator small double door
                </div>
                <span className="flex items-center leading-normal">
                  <span className="fs-11 font-bold me-1">
                    MMK
                  </span>
                  <span className="fs-16 font-extrabold leading-[16px]">
                    260,000
                  </span>
                </span>
                <span className="flex items-center text-300 leading-normal">
                  <span className="fs-11 me-1">
                    was
                  </span>
                  <span className="fs-11">
                    MMK
                  </span>
                  <span className="fs-11 line-through leading-[16px] me-1">
                    560,000
                  </span>
                  <span className="fs-11 font-medium text-500">
                    60% off
                  </span>
                </span>

                <span className="fs-11 text-red-500 leading-[16px]">
                  free shipping
                </span>
              </div>
            </div>

            <div className="flex flex-col relative bg-foundation-grey-light-hover rounded-b-md">
              <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                <span className="text-white fs-8 font-medium">
                  60% off
                </span>
              </div>

              <a href="product-detail">
                <img src={LuckydrawItem1} alt="Slide 1" className="w-full rounded-t-md" />
              </a>

              <div className="p-lr-8 p-tb-16">
                <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                  Household refrigerator small double door
                </div>
                <span className="flex items-center leading-normal">
                  <span className="fs-11 font-bold me-1">
                    MMK
                  </span>
                  <span className="fs-16 font-extrabold leading-[16px]">
                    260,000
                  </span>
                </span>
                <span className="flex items-center text-300 leading-normal">
                  <span className="fs-11 me-1">
                    was
                  </span>
                  <span className="fs-11">
                    MMK
                  </span>
                  <span className="fs-11 line-through leading-[16px] me-1">
                    560,000
                  </span>
                  <span className="fs-11 font-medium text-500">
                    60% off
                  </span>
                </span>

                <span className="fs-11 text-red-500 leading-[16px]">
                  free shipping
                </span>
              </div>
            </div>

            <div className="flex flex-col relative bg-foundation-grey-light-hover rounded-b-md">
              <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                <span className="text-white fs-8 font-medium">
                  60% off
                </span>
              </div>

              <a href="product-detail">
                <img src={LuckydrawItem1} alt="Slide 1" className="w-full rounded-t-md" />
              </a>

              <div className="p-lr-8 p-tb-16">
                <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                  Household refrigerator small double door
                </div>
                <span className="flex items-center leading-normal">
                  <span className="fs-11 font-bold me-1">
                    MMK
                  </span>
                  <span className="fs-16 font-extrabold leading-[16px]">
                    260,000
                  </span>
                </span>
                <span className="flex items-center text-300 leading-normal">
                  <span className="fs-11 me-1">
                    was
                  </span>
                  <span className="fs-11">
                    MMK
                  </span>
                  <span className="fs-11 line-through leading-[16px] me-1">
                    560,000
                  </span>
                  <span className="fs-11 font-medium text-500">
                    60% off
                  </span>
                </span>

                <span className="fs-11 text-red-500 leading-[16px]">
                  free shipping
                </span>
              </div>
            </div>

            <div className="flex flex-col relative bg-foundation-grey-light-hover rounded-b-md">
              <div className="absolute top-2 left-1 flex items-center justify-between bg-red-500 text-white font-semibold rounded-full p-lr-8 m-l-4">
                <span className="text-white fs-8 font-medium">
                  60% off
                </span>
              </div>

              <a href="product-detail">
                <img src={LuckydrawItem1} alt="Slide 1" className="w-full rounded-t-md" />
              </a>

              <div className="p-lr-8 p-tb-16">
                <div className="fs-11 overflow-hidden whitespace-nowrap text-ellipsis">
                  Household refrigerator small double door
                </div>
                <span className="flex items-center leading-normal">
                  <span className="fs-11 font-bold me-1">
                    MMK
                  </span>
                  <span className="fs-16 font-extrabold leading-[16px]">
                    260,000
                  </span>
                </span>
                <span className="flex items-center text-300 leading-normal">
                  <span className="fs-11 me-1">
                    was
                  </span>
                  <span className="fs-11">
                    MMK
                  </span>
                  <span className="fs-11 line-through leading-[16px] me-1">
                    560,000
                  </span>
                  <span className="fs-11 font-medium text-500">
                    60% off
                  </span>
                </span>

                <span className="fs-11 text-red-500 leading-[16px]">
                  free shipping
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="container p-lr-16 m-b-16">
        </section>
      </div>
    </>
  )
}